import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {RepositorioDocumentosService} from '../../../../services/_Sis/RepositorioDocumentosService/RepositorioDocumentosService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Group, CompareArrows, CloudDownload, Cancel} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import {PopupService} from "../../../../settings/PoPup/PoPup";


class ModalCompartir extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			id_repositorio_juridico: (props.item || '').id_repositorio_juridico,
			id_usuario: '',
			activo: true,
			
			lista_usuarios: [],
			usuarios_compartir: [],
			lista_usuarios_compartidos: []
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListUsuarios = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuarioTipoReporte().then((response) => {
				this.setState({
					lista_usuarios: response.data
				});
				resolve(response);
			});
		});
	};
	
	listUsuariosCompartidos = () => {
		return new Promise((resolve, reject) => {
			RepositorioDocumentosService.UsuariosCompartidos(this.state).then((response) => {
				this.setState({
					lista_usuarios_compartidos: response.data
				});
				resolve(response);
			});
		});
	};
	
	asignarUsuarioReporte = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuarios);
		let users = FieldsJs.Copy(this.state.usuarios_compartir);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
		}
		this.setState({
			lista_usuarios: usuarios,
			usuarios_compartir: users,
		});
	};
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.usuarios_compartir) && this.state.usuarios_compartir.length > 0) {
				throw Object({
					status: false, mensaje: 'Por lo menos un usuario es requerido.'
				})
			}
			
			if (this.state.id_repositorio_juridico > 0) {
				RepositorioDocumentosService.Compartir(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				this.showSnackBars('error', 'Por lo menos un usuario es requerido.');
			}
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_repositorio_juridico: item.id_repositorio_juridico || '',
			id_usuario: '',
			usuarios_compartir: [],
			activo: true,
		});
		
		this.ListUsuarios();
		this.listUsuariosCompartidos();
		
		this.openModal();
		
	};
	
	RefreshList = () => {
		this.listUsuariosCompartidos();
	}
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_repositorio_juridico > 0) {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Compartir">
					<IconButton aria-label="Compartir" onClick={() => this.add()}>
						<CompareArrows/>
					</IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	eliminarDocumentoCompartido = (item) => {
		let msg = `¿Deseas dejar de compartir el repositorio jurídico de: ${item.nombre_completo}?`;
		
		PopupService.Confirm(['Cancelar', 'Dejar de Compartir'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Dejar de Compartir') {
				RepositorioDocumentosService.Descompartir(item).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	}
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Compartir Documentos'}</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={0} alignItems={"flex-center"} className={'text-left borde-punteado-lightgray-2'}>
									
									<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'vertical-inline padding-5'}>
											<div className={'w-30-px'}>
												<Group className={'w-100 text-gray'}/>
											</div>
											<div className={'w-100-30-px'}>
												<Typography className={'px-14 text-left text-gray margin-2-T'}>
													{'Compartir a:'}
												</Typography>
											</div>
										</div>
									</Grid>
									<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
										<div style={{maxHeight: 280, overflowY: 'auto'}}>
											{this.state.lista_usuarios.map((item, index) => (
												<Fragment key={index}>
													<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => {
														if (this.props.tipo !== 'view') {
															this.asignarUsuarioReporte(item.id_usuario);
														}
													}}>
														<div className='v-center padding-10-L'>
															<FormGroup row className={'text-center check-recuser'}>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			color="primary"
																			checked={item.check}
																			value={item.check}
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={''}
																/>
															</FormGroup>
														</div>
														
														<div className={'v-center vertical-inline'}>
															<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
															     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
															</div>
															<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																{item.nombre_completo}
															</Typography>
														</div>
													</div>
												</Fragment>
											))}
										</div>
									</Grid>
								</Grid>
							</Grid>
							{this.state.lista_usuarios_compartidos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container spacing={0} alignItems={"flex-center"} className={'text-left borde-punteado-lightgray-2'}>
										
										<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
											<div className={'vertical-inline padding-5'}>
												<div className={'w-30-px'}>
													<CloudDownload style={{color: 'rgb(142, 30, 50)'}}/>
												</div>
												<div className={'w-100-30-px'}>
													<Typography className={'px-14 text-left text-gray margin-2-T'}>
														{'Documentos compartidos con:'}
													</Typography>
												</div>
											</div>
										</Grid>
										<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
											<div style={{maxHeight: 280, overflowY: 'auto'}}>
												{this.state.lista_usuarios_compartidos.map((item, index) => (
													<Fragment key={index}>
														<div className={'vertical-inline w-95 cursor-pointer padding-5'} onClick={() => {
															if (this.props.tipo !== 'view') {
																this.asignarUsuarioReporte(item.id_usuario);
															}
														}}>
															
															<div className={'v-center vertical-inline w-100'}>
																<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																</div>
																<Typography variant={'h5'} className={'v-center w-100-60-px padding-15-L margin-0 text-black px-13'}>
																	{item.nombre_completo}
																</Typography>
																<div className={'v-center w-30-px h-30-px'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Dejar de Compartir">
																		<IconButton aria-label="Dejar de Compartir" onClick={() => this.eliminarDocumentoCompartido(item)}>
																			<Cancel style={{color: '#8E1E32', fontSize: '25px'}}/>
																		</IconButton>
																	</Tooltip>
																</div>
															</div>
														</div>
													</Fragment>
												))}
											</div>
										</Grid>
									</Grid>
								</Grid>) : null}
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{'Compartir'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCompartir.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalCompartir);
