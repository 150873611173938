import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {RutaService} from "../../../../services/_Sis/RutaService/RutaService";
import ModalMatrizRiesgo from "./ModalMatrizRiesgo";
import {CONFIG} from "../../../../settings/Config/Config";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import icon_ticket from "../../../../assets/img/icons/icon-ticket.svg";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {MatrizRiesgoService} from "../../../../services/_Sis/MatrizRiesgoService/MatrizRiesgoService";
import BarraProgreso from "./BarraProgreso";
import {BotonExportarListaExcel} from "../../../Include/MiniComponents/GlobalComponent";
import {Icono} from "../../../Include/Icono/Icono";


class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el expediente con id: ${item.id_expediente}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				MatrizRiesgoService.Eliminar(item.id_expediente).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	ticket_de_entrega = (value) => {
		HttpRequest.export('texid', ['expediente'], {
			id_expediente: value.id_expediente
		}, true, 900, 900)
	}
	
	generacionPDF = (item) => {
		HttpRequest.export('pdfmr', ['matriz_riesgo'], {
			id_matriz_riesgo: item.id_matriz_riesgo
		}, true, 900, 900)
	};
	
	render() {
		
		const {lista} = this.props;
		
		const bg_icon_ticket = {
			backgroundImage: `url(${icon_ticket})`
		};
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} className={'w-40-px padding-20-L padding-20-R'}>
								ID
							</TableCell>
							<TableCell component="th" align={'left'}>Acreditado</TableCell>
							<TableCell component="th" align={'center'}>Tipo Crédito</TableCell>
							<TableCell component="th" align={'center'}>Monto Ingreso</TableCell>
							<TableCell component="th" align={'center'}>Tipo Moneda</TableCell>
							<TableCell component="th" align={'center'}>Forma de Pago</TableCell>
							<TableCell component="th" align={'center'}>Score</TableCell>
							<TableCell component="th" align={'center'}>Nivel de Riesgo</TableCell>
							<TableCell component="th" align={'center'}>Reporte</TableCell>
							<TableCell component="th" align={'center'} className={'w-200-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
									{item.id_matriz_riesgo}
								</TableCell>
								<TableCell align={'left'}>{Number(item.id_cat_tipo_persona) === 1 ? item.nombre_completo : item.nombre_comercial}</TableCell>
								<TableCell align={'center'}>{item.tipo_credito}</TableCell>
								<TableCell align={'center'}>{item.monto_ingreso}</TableCell>
								<TableCell align={'center'}>{item.tipo_moneda}</TableCell>
								<TableCell align={'center'}>{item.forma_pago}</TableCell>
								<TableCell align={'center'}>{item.valor_total}</TableCell>
								<TableCell align={'center'}>{item.nivel_riesgo}</TableCell>
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar" onClick={() => {this.generacionPDF(item);}}>
													{Icono.get('pdf', '25px', '25px')}
												</IconButton>
											</Tooltip>
										</div>
									</div>
								</TableCell>
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalMatrizRiesgo
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalMatrizRiesgo
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
