import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {HdrStrong, MailOutline, PersonOutlined, SearchOutlined, FiberManualRecord, Subtitles, CalendarTodayOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {KeyboardDatePicker} from '@material-ui/pickers';


class FiltrosReclamaciones extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			id_expediente: '',
			usuario: '',
			curp: '',
			rfc: '',
			id_cat_identificacion: '',
			fecha_ingreso: null,
			activo: 'all',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_expediente = this.state.id_expediente || null;
		filtro.nombre = this.state.nombre || null;
		filtro.curp = this.state.curp || null;
		filtro.rfc = this.state.rfc || null;
		filtro.id_cat_identificacion = this.state.id_cat_identificacion || null;
		filtro.fecha_ingreso = this.state.fecha_ingreso || null;
		filtro.activo = this.state.activo || null;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={12} sm={12} md={2} lg={1} xl={1} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<FiberManualRecord className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="id_expediente"
									onChange={this.handleChange}
									label="ID" autoComplete={'off'}
									value={this.state.id_expediente}
									inputProps={{maxLength: 10}}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={6} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="nombre"
									onChange={this.handleChange}
									label="Nombre"
									autoComplete={'off'}
									value={this.state.nombre}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Subtitles className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="curp"
									onChange={this.handleChange}
									label="CURP"
									autoComplete={'off'}
									value={this.state.curp}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Subtitles className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="rfc"
									onChange={this.handleChange}
									label="RFC"
									autoComplete={'off'}
									value={this.state.rfc}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={6} lg={2} xl={2} >
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<CalendarTodayOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha de Ingreso"
									value={this.state.fecha_ingreso}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_ingreso');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={6} lg={1} xl={1} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosReclamaciones.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosReclamaciones;
