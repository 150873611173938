import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const MatrizRiesgoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_matriz_riesgo: filtro.id_matriz_riesgo || null,
				/*nombre: filtro.nombre || null,
				curp: filtro.curp || null,
				rfc: filtro.rfc || null,
				id_cat_identificacion: filtro.id_cat_identificacion || null,
				fecha_ingreso: fecha_ingreso || null,*/
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : 'all',
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Matriz_Riesgo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_matriz_riesgo: form.id_matriz_riesgo || '',
			id_acreditado: form.id_acreditado || '',
			id_cat_nacionalidad: form.id_cat_nacionalidad || '',
			id_cat_estado_republica: form.id_cat_estado_republica || '',
			id_cat_tipo_cliente: form.id_cat_tipo_cliente || '',
			id_cat_peps: form.id_cat_peps || '',
			id_cat_edad_cliente: form.id_cat_edad_cliente || '',
			id_cat_antiguedad_empresa: form.id_cat_antiguedad_empresa || '',
			id_cat_persona_bloqueada: form.id_cat_persona_bloqueada || '',
			id_cat_tipo_credito: form.id_cat_tipo_credito || '',
			id_cat_actividad_economica: form.id_cat_actividad_economica || '',
			id_cat_actua_cuenta_propia: form.id_cat_actua_cuenta_propia || '',
			id_cat_numero_transacciones: form.id_cat_numero_transacciones || '',
			id_cat_monto_transacciones: form.id_cat_monto_transacciones || '',
			id_cat_monto_ingreso: form.id_cat_monto_ingreso || '',
			id_cat_cambio_perfil_transaccional: form.id_cat_cambio_perfil_transaccional || '',
			id_cat_tipo_moneda: form.id_cat_tipo_moneda || '',
			id_cat_forma_pago: form.id_cat_forma_pago || '',
			id_cat_domicilio: form.id_cat_domicilio || '',
			id_cat_visita_ocular: form.id_cat_visita_ocular || '',
			id_cat_otro_riesgo: form.id_cat_otro_riesgo || '',
			id_cat_alerta_emitida: form.id_cat_alerta_emitida || '',
			id_cat_mitigante: form.id_cat_mitigante || '',
			id_cat_autorizacion: form.id_cat_autorizacion || '',
			id_cat_cumplimiento: form.id_cat_cumplimiento || '',
			ponderacion: form.ponderacion || '',
			observaciones: form.observaciones || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Matriz_Riesgo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_matriz_riesgo: form.id_matriz_riesgo || '',
			id_acreditado: form.id_acreditado || '',
			id_cat_nacionalidad: form.id_cat_nacionalidad || '',
			id_cat_estado_republica: form.id_cat_estado_republica || '',
			id_cat_tipo_cliente: form.id_cat_tipo_cliente || '',
			id_cat_peps: form.id_cat_peps || '',
			id_cat_edad_cliente: form.id_cat_edad_cliente || '',
			id_cat_antiguedad_empresa: form.id_cat_antiguedad_empresa || '',
			id_cat_persona_bloqueada: form.id_cat_persona_bloqueada || '',
			id_cat_tipo_credito: form.id_cat_tipo_credito || '',
			id_cat_actividad_economica: form.id_cat_actividad_economica || '',
			id_cat_actua_cuenta_propia: form.id_cat_actua_cuenta_propia || '',
			id_cat_numero_transacciones: form.id_cat_numero_transacciones || '',
			id_cat_monto_transacciones: form.id_cat_monto_transacciones || '',
			id_cat_monto_ingreso: form.id_cat_monto_ingreso || '',
			id_cat_cambio_perfil_transaccional: form.id_cat_cambio_perfil_transaccional || '',
			id_cat_tipo_moneda: form.id_cat_tipo_moneda || '',
			id_cat_forma_pago: form.id_cat_forma_pago || '',
			id_cat_domicilio: form.id_cat_domicilio || '',
			id_cat_visita_ocular: form.id_cat_visita_ocular || '',
			id_cat_otro_riesgo: form.id_cat_otro_riesgo || '',
			id_cat_alerta_emitida: form.id_cat_alerta_emitida || '',
			id_cat_mitigante: form.id_cat_mitigante || '',
			id_cat_autorizacion: form.id_cat_autorizacion || '',
			id_cat_cumplimiento: form.id_cat_cumplimiento || '',
			ponderacion: form.ponderacion || '',
			observaciones: form.observaciones || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Matriz_Riesgo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_matriz_riesgo) => {let params = {
			id_matriz_riesgo: id_matriz_riesgo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Matriz_Riesgo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
