import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {RepositorioDocumentosService} from '../../../../services/_Sis/RepositorioDocumentosService/RepositorioDocumentosService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {Business, DeleteOutlined, Edit, Group, PlaylistAddOutlined, SpeakerNotes, Sync} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import ModalTipoDocumentoRepositorioDocumentos from "../../Cat/TipoDocumentoRepositorioDocumentos/Includes/ModalTipoDocumentoRepositorioDocumentos";
import moment from "moment";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalCliente from "../../Cliente/Includes/ModalCliente";
import {ClienteService} from "../../../../services/_Sis/ClienteService/ClienteService";

class ModalRepositorioDocumentos extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			id_repositorio_juridico: (props.item || '').id_repositorio_juridico,
			
			observaciones: '',
			id_cat_tipo_documento_repositorio_juridico: '',
			id_cat_tipo_documento_pfpm_repositorio_juridico: '',
			lista_cat_tipo_documento_pfpm: [],
			numero: '',
			nombre: '',
			empresa: '',
			id_acreditado: '',
			rfc: '',
			curp: '',
			fecha: null,
			activo: true,
			
			base64: '',
			documento: '',
			formato: '',
			
			MegaByte: '',
			base64Tipo: '',
			
			files: [],
			archivos: [],
			
			
			listar_cliente: [],
			
			lista_cat_tipo_documento_rj: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListDocumentosRJ = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDocumentoRJ(this.Usr.id_cat_tipo_usuario).then((response) => {
				this.setState({
					lista_cat_tipo_documento_rj: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_tipo_documento_rj: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListTipoDocumentoPFPM = (id_cat_tipo_documento_repositorio_juridico) => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDocumentoPFPM(id_cat_tipo_documento_repositorio_juridico).then((response) => {
				this.setState({
					lista_cat_tipo_documento_pfpm: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_tipo_documento_pfpm: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListaCliente = () => {
		return new Promise((resolve, reject) => {
			ClienteService.Listar({}, {}).then((response) => {
				this.setState({
					listar_cliente: response.data,
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_cliente: []
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	RefreshListDocumentosRJ = (id_cat_tipo_documento_repositorio_juridico) => {
		this.ListDocumentosRJ().then((response) => {
			this.setState({
				id_cat_tipo_documento_repositorio_juridico: id_cat_tipo_documento_repositorio_juridico
			});
		});
	};
	
	RefreshListCliente = (id_acreditado) => {
		if (id_acreditado > 0) {
			this.setState({
				id_acreditado: id_acreditado
			})
		}
		this.ListaCliente();
	};
	
	getBase64 = (file) => {
		let formatos = [
			"image/jpeg",
			"image/png",
			"text/xml",
			"application/pdf",
			"application/x-x509-ca-cert",
			"application/key"
		];
		let fil = {files: [file]};
		FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
			this.handleChange({
				target: {
					name: 'base64',
					value: response.base64
				}
			});
			this.handleChange({
				target: {
					name: 'base64Tipo',
					value: response.base64Tipo
				}
			});
			this.handleChange({
				target: {
					name: 'documento',
					value: response.archivo
				}
			});
			this.handleChange({
				target: {
					name: 'formato',
					value: response.formato
				}
			});
		}).catch((error) => {
			
			this.props.showSnackBars('error', error.mensaje);
			this.setState({
				base64: '',
				documento: '',
				formato: '',
			});
		});
	};
	
	save = () => {
		
		try {
			
			/*if (!FieldsJs.Field(this.state.files) && this.state.files.length > 0 && this.state.tipo === 'add') {
				throw Object({
					status: false, mensaje: 'Por lo menos un documento es requerido.'
				})
			}*/
			
			if (this.state.id_repositorio_juridico > 0) {
				RepositorioDocumentosService.Modificar(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				RepositorioDocumentosService.Agregar(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.ListaCliente();
		
		this.setState({
			id_repositorio_juridico: '',
			id_cat_tipo_documento_repositorio_juridico: '',
			id_cat_centro_trabajo_repositorio_juridico: '',
			id_empresa: '1',
			direccion: '',
			responsable: '',
			representante_legal: '',
			administrador_unico: '',
			observaciones: '',
			razon_social: '',
			empresa_datos: {},
			fecha: null,
			direccion_rp: '',
			numero_registro_patronal: '',
			password: '',
			rfc: '',
			curp: '',
			nombre: '',
			id_acreditado: '',
			codigo_postal: '',
			id_cat_regimen_fiscal: '',
			fisicamente_rl: 'No',
			
			files: [],
			archivos: [],
			activo: true,
		});
		
		this.ListDocumentosRJ();
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.ListDocumentosRJ().then(() => {
			this.setState({
				id_cat_tipo_documento_repositorio_juridico: item.id_cat_tipo_documento_repositorio_juridico || '',
			});
		});
		
		this.ListTipoDocumentoPFPM(item.id_cat_tipo_documento_repositorio_juridico).then(() => {
			this.setState({
				id_cat_tipo_documento_pfpm_repositorio_juridico: item.id_cat_tipo_documento_pfpm_repositorio_juridico || '',
			});
		});
		
		this.ListaCliente().then(() => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		
		this.setState({
			id_repositorio_juridico: item.id_repositorio_juridico || '',
			id_cat_centro_trabajo_repositorio_juridico: item.id_cat_centro_trabajo_repositorio_juridico || '',
			id_empresa: '1',
			direccion: item.direccion || '',
			responsable: '',
			representante_legal: item.representante_legal || '',
			administrador_unico: item.administrador_unico || '',
			observaciones: item.observaciones || '',
			razon_social: item.razon_social || '',
			empresa_datos: {},
			fecha: item.fecha ? moment(item.fecha) : null,
			direccion_rp: item.direccion_rp || '',
			numero_registro_patronal: item.numero_registro_patronal || '',
			password: '',
			rfc: item.rfc || '',
			curp: item.curp || '',
			numero: item.numero || '',
			nombre: item.nombre || '',
			codigo_postal: item.codigo_postal || '',
			id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || '',
			fisicamente_rl: 'No',
			archivos: item.archivo ? [{archivo: item.archivo}] : [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.ListaCliente().then(() => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		
		this.ListDocumentosRJ().then(() => {
			this.setState({
				id_cat_tipo_documento_repositorio_juridico: item.id_cat_tipo_documento_repositorio_juridico || '',
			});
		});
		
		this.ListTipoDocumentoPFPM(item.id_cat_tipo_documento_repositorio_juridico).then(() => {
			this.setState({
				id_cat_tipo_documento_pfpm_repositorio_juridico: item.id_cat_tipo_documento_pfpm_repositorio_juridico || '',
			});
		});
		
		this.setState({
			id_repositorio_juridico: item.id_repositorio_juridico || '',
			id_cat_centro_trabajo_repositorio_juridico: item.id_cat_centro_trabajo_repositorio_juridico || '',
			id_empresa: '1',
			direccion: item.direccion || '',
			responsable: '',
			representante_legal: item.representante_legal || '',
			administrador_unico: item.administrador_unico || '',
			observaciones: item.observaciones || '',
			razon_social: item.razon_social || '',
			empresa_datos: {},
			fecha: item.fecha ? moment(item.fecha) : null,
			direccion_rp: item.direccion_rp || '',
			numero_registro_patronal: item.numero_registro_patronal || '',
			password: '',
			rfc: item.rfc || '',
			curp: item.curp || '',
			numero: item.numero || '',
			nombre: item.nombre || '',
			codigo_postal: item.codigo_postal || '',
			id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || '',
			fisicamente_rl: 'No',
			archivos: item.archivo ? [{archivo: item.archivo}] : [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_repositorio_juridico > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		},() => {
			this.getBase64(files[0]);
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (archivo) => {
		
		if ( archivo ) {
			const url = CONFIG.src + archivo;
			window.open(url, "blank", "width=500,height=500");
		}
		
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Repositorio</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<div className={'row-flex'}>
									<div className={'w-50-px v-end'}>
										<ModalTipoDocumentoRepositorioDocumentos
											tipo={'add'}
											item={{}}
											componente={
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar Equipo">
													<Fab size="small" color="primary" aria-label="Agregar Equipo">
														<Add/>
													</Fab>
												</Tooltip>
											}
											RefreshList={this.RefreshListDocumentosRJ}
											showSnackBars={this.props.showSnackBars}
										/>
									</div>
									<div className={'w-100-50-px padding-10-R'}>
										<FormControl fullWidth required>
											<InputLabel>Tipo Documento</InputLabel>
											<Select
												native
												value={this.state.id_cat_tipo_documento_repositorio_juridico}
												onChange={(e) => {
													this.handleChange(e);
													this.ListTipoDocumentoPFPM(e.target.value);
												}}
												name="id_cat_tipo_documento_repositorio_juridico"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_cat_tipo_documento_rj.map(option => (
													<option key={option.id_cat_tipo_documento_repositorio_juridico}
													        value={option.id_cat_tipo_documento_repositorio_juridico}>
														{option.tipo_documento}
													</option>
												))}
											</Select>
										</FormControl>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									
									<Grid item style={{width: '50px'}}>
										<ModalCliente
											componente={(
												<Tooltip
													TransitionComponent={Zoom}
													placement={"right"}
													title="Agregar nueva empresa/cliente"
													children={(
														<Fab
															color="default"
															size="small"
															aria-label="Agregar nueva empresa/cliente"
															children={(
																<PlaylistAddOutlined/>
															)}
														/>
													)}
												/>
											)}
											id_acreditado={null}
											tipo={'add'}
											item={{}}
											RefreshList={this.RefreshListCliente}
											showSnackBars={this.props.showSnackBars}
										/>
									</Grid>
									
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_acreditado"
											label="Acreditado"
											value={this.state.id_acreditado}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_cliente.map(option => (
												<option key={option.id_acreditado} value={option.id_acreditado}>
													{option.id_cat_tipo_persona === 1 ? option.nombre_completo : option.nombre_comercial}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							{Number(this.state.id_cat_tipo_documento_repositorio_juridico) === 4 || Number(this.state.id_cat_tipo_documento_repositorio_juridico) === 5 ? (
								<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
									<FormControl fullWidth required>
										<InputLabel>Tipo Documento {Number(this.state.id_cat_tipo_documento_repositorio_juridico) === 4 ? "(PF)" : "(PM)"}</InputLabel>
										<Select
											native
											value={this.state.id_cat_tipo_documento_pfpm_repositorio_juridico}
											onChange={(e) => {
												this.handleChange(e);
											}}
											name="id_cat_tipo_documento_pfpm_repositorio_juridico"
											input={<Input/>}
											disabled={this.props.tipo === 'view'}
										>
											<option value=""/>
											{this.state.lista_cat_tipo_documento_pfpm.map(option => (
												<option key={option.id_cat_tipo_documento_pfpm_repositorio_juridico}
												        value={option.id_cat_tipo_documento_pfpm_repositorio_juridico}>
													{option.documento}
												</option>
											))}
										</Select>
									</FormControl>
								</Grid>
							) : null}
							
							{FieldsJs.inArray([5, 6, 7], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<TextField
										fullWidth
										required
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
										type="text"
										className={'margin-0'}
										margin="dense"
										name="empresa"
										label={"Nombre de la empresa"}
										value={this.state.empresa}
									/>
								</Grid>
							) : null}
							
							{/*{FieldsJs.inArray([1], this.state.id_cat_tipo_documento_repositorio_juridico) || FieldsJs.inArray([1, 2, 3, 4, 7], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<TextField
										fullWidth
										required
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
										type="text"
										className={'margin-0'}
										margin="dense"
										name="nombre"
										label={FieldsJs.inArray([1], this.state.id_cat_tipo_documento_repositorio_juridico) ? "Representante Legal" :
											FieldsJs.inArray([1, 2, 3, 4], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? "Nombre" :
												"Nombre Socio"
										}
										value={this.state.nombre}
									/>
								</Grid>
							) : null}*/}
							
							{FieldsJs.inArray([1], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<TextField
										type={'text'}
										required
										fullWidth name="curp"
										onChange={this.handleChange}
										inputProps={{maxLength: 18}}
										label="CURP" autoComplete={'off'}
										value={this.state.curp}
										disabled={this.state.tipo === 'view'}
									/>
								</Grid>
							) : null}
							
							{FieldsJs.inArray([6], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<TextField
										type={'text'}
										required
										fullWidth name="rfc"
										onChange={this.handleChange}
										inputProps={{maxLength: 13}}
										label="RFC" autoComplete={'off'}
										value={this.state.rfc}
										disabled={this.state.tipo === 'view'}
									/>
								</Grid>
							) : null}
							
							{FieldsJs.inArray([1, 2, 3], this.state.id_cat_tipo_documento_repositorio_juridico) || FieldsJs.inArray([3, 5, 7], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<TextField
										fullWidth
										required
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
										type="text"
										className={'margin-0'}
										margin="dense"
										name="numero"
										label={FieldsJs.inArray([1], this.state.id_cat_tipo_documento_repositorio_juridico) ? "Número de Instrumento Notarial" :
											FieldsJs.inArray([2, 3], this.state.id_cat_tipo_documento_repositorio_juridico) ? "Número de documento" :
												FieldsJs.inArray([3], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? "No. de Identificación" :
													FieldsJs.inArray([5], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? "Número de Instrumento" :
														"No. INE Socio"}
										value={this.state.numero}
									/>
								</Grid>
							) : null}
							
							{FieldsJs.inArray([1, 2, 3], this.state.id_cat_tipo_documento_repositorio_juridico) || FieldsJs.inArray([2, 4, 5, 6], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<KeyboardDatePicker
										format="dd/MM/yyyy"
										fullWidth
										required
										clearable
										inputProps={{readOnly: true}}
										/*KeyboardButtonProps={{
											'aria-label': 'Fecha de pago',
										}}*/
										label={FieldsJs.inArray([1], this.state.id_cat_tipo_documento_repositorio_juridico) ? "Fecha de Instrumento" :
											FieldsJs.inArray([2, 3], this.state.id_cat_tipo_documento_repositorio_juridico) ? "Fecha del Documento" :
												FieldsJs.inArray([2], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? "Fecha de Nacimiento" :
													FieldsJs.inArray([4, 6], this.state.id_cat_tipo_documento_pfpm_repositorio_juridico) ? "Fecha de Emisión de la Constancia" :
														"Fecha del Instrumento"}
										value={this.state.fecha}
										onChange={(date) => {
											this.handleChange(null, null, null, date, 'fecha');
										}}
										// minDate={moment(new Date())}
										clearLabel={'Limpiar'}
										okLabel={'Aceptar'}
										cancelLabel={'Cancelar'}
										onError={console.log}
										disabled={this.state.tipo === 'view'}
									/>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={2}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'padding-10-T padding-0-B px-14 text-left'} style={{fontWeight: 'bold'}}>
									Documentos:
								</Typography>
								<div className={'vertical-inline'}>
									{this.state.archivos.length > 0 ? (
										<Fragment>
											{this.state.archivos.map((item, key) => (
												<div key={key}>
													<div className={'vertical-inline b-r-20 margin-5 margin-0-L'}>
														{FieldsJs.Field(item.thumb) ? (
															<img className={'v-center cursor-pointer'} src={`data:image/jpeg;base64,${item.thumb.thumbnail}`} alt="Archivo" width={70} height={70} style={{opacity: 0.7, marginRight: '5px'}}
															     onClick={() => this.descargarZip(item.archivo)}/>
														) : (
															<img className={'v-center cursor-pointer'} src={DocSvg} alt="Archivo" width={70} height={70} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
														)}
														
														{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
															<div className={'padding-3 v-end text-white cursor-pointer'}>
																{this.props.tipo === 'view' ? null : (
																	<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
																)
																}
															</div>
														</div>) : null}
													</div>
												</div>
											))}
										</Fragment>
									) : null}
								</div>
								
								{this.props.tipo !== 'view' ? (
									<div className={'w-100 dropzone-cronograma'}>
										<div className={'w-100'}>
											<DropzoneArea
												onChange={this.handleChangeDropzoneArea.bind(this)}
												open={this.state.open}
												filesLimit={1}
												dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
												//acceptedFiles={['text/xml', 'application/pdf']}
												showPreviews={false}
												maxFileSize={20000000}
												showAlerts={false}
												showFileNames={true}
												showFileNamesInPreview={true}
											/>
										</div>
									</div>
								) : null}
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_repositorio_juridico > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalRepositorioDocumentos.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalRepositorioDocumentos);
