import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListaTabla from './Includes/ListaTabla';
import ModalReclamaciones from './Includes/ModalReclamaciones';

import {ReclamacionesService} from '../../../services/_Sis/ReclamacionesService/ReclamacionesService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {
	BotonActualizarLista,
	BotonExportarListaExcel,
	BotonExportarListaPDF,
	CabeceraTituloPdfExcelLista
} from "../../Include/MiniComponents/GlobalComponent";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosReclamaciones from "./Includes/FiltrosReclamaciones";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";


class Reclamaciones extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			listar_matriz_riesgo: [],
			
			filtro: {
				id_expediente: '',
				nombre: '',
				curp: '',
				rfc: '',
				id_cat_identificacion: '',
				fecha_ingreso: null,
				activo: 'all'
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		this.Listar();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		ReclamacionesService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_matriz_riesgo: [],
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				listar_matriz_riesgo: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {};
		
		HttpRequest.export('matriz_riesgo', ['matriz_riesgo'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Reclamaciones'}
					botonLISTA={
						<Fragment>
							<div className={'v-center'}>
								<BotonActualizarLista onClick={() => {
									this.Listar();
								}}/>
							</div>
						</Fragment>
					}
					/*botonEXCEL={
						<Fragment>
							<div className={'v-center'}>
								<Fragment>
									<BotonExportarListaExcel onClick={() => this.exportar({}, 'EXCEL', true)}/>
								</Fragment>
							</div>
						</Fragment>
					}*/
				/>
				
				{/*<FiltrosExpedientes
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>*/}
				
				<div className={'form margin-30-B margin-15-T'}>
					
					{this.state.listar_matriz_riesgo.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_matriz_riesgo}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalReclamaciones
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Reclamaciones.propTypes = {};

export default IntegrationNotistack(Reclamaciones);
