import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CatService = {
	Cfg: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/config').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodoPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodo_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMunicipio: (id_estado) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/municipio/' + id_estado).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSexo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/sexo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoSangre: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_sangre').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGiroCliente: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/giro_cliente').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioEjecutivo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_ejecutivo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioPromotor: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_promotor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClienteInternaPlazaDireccion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cliente_interna_plaza_direccion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioAcreditado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_acreditado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListComprobanteIngreso: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/comprobante_ingreso').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListIdentificacion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/identificacion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListArchivoIntegracionExpediente: (form) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/archivo_integracion_expediente').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCentroTrabajoRJ: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/centro_trabajo_repositorio_juridico').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoDocumentoRJ: (id_cat_tipo_usuario) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_documento_repositorio_juridico/'+id_cat_tipo_usuario).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListRegimenFiscal: (tipo_persona) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/regimen_fiscal/'+tipo_persona).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPais: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/pais').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoDocumentoPFPM: (id_cat_tipo_documento_repostorio_juridico) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_documento_pfpm_repositorio_juridico/'+id_cat_tipo_documento_repostorio_juridico).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListRepositorioJuridicoEstatus: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/repositorio_juridico_estatus').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMatrizRiesgo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/matriz_riesgo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListReclamaciones: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/matriz_riesgo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
