import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import {HowToVoteOutlined, CalendarTodayOutlined, DeviceHubOutlined, PinDropOutlined, VerifiedUser, PhoneOutlined} from "@material-ui/icons"
import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {RutaService} from "../../../../services/_Sis/RutaService/RutaService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {MatrizRiesgoService} from "../../../../services/_Sis/MatrizRiesgoService/MatrizRiesgoService";
import {KeyboardDatePicker} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from "@material-ui/core/Typography";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ArchivosExpedientes from "./ArchivosExpedientes";
import SeccionExpedientes from "./SeccionExpedientes";
import {CONFIG} from "../../../../settings/Config/Config";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {Dropbox} from 'dropbox';
import Fab from "@material-ui/core/Fab";
import ModalValidacion from "./ModalValidacion";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import moment from "moment";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {ClienteService} from "../../../../services/_Sis/ClienteService/ClienteService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class ModalMatrizRiesgo extends Component {
	
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_matriz_riesgo: (props.item || {}).id_matriz_riesgo,
			id_acreditado: '',
			
			id_cat_nacionalidad: '',
			id_cat_estado_republica: '',
			id_cat_tipo_cliente: '',
			id_cat_peps: '',
			id_cat_edad_cliente: '',
			id_cat_antiguedad_empresa: '',
			id_cat_persona_bloqueada: '',
			id_cat_tipo_credito: '',
			id_cat_actividad_economica: '',
			id_cat_actua_cuenta_propia: '',
			id_cat_numero_transacciones: '',
			id_cat_monto_transacciones: '',
			id_cat_monto_ingreso: '',
			id_cat_cambio_perfil_transaccional: '',
			id_cat_tipo_moneda: '',
			id_cat_forma_pago: '',
			id_cat_domicilio: '',
			id_cat_visita_ocular: '',
			id_cat_otro_riesgo: '',
			id_cat_alerta_emitida: '',
			id_cat_mitigante: '',
			id_cat_autorizacion: '',
			id_cat_cumplimiento: '',
			ponderacion: '',
			observaciones: '',
			valor_total: 0,
			
			activo: true,
			
			listar_usuario_acreditado: [],
			listar_cat_nacionalidad: [],
			listar_cat_estado_republica: [],
			listar_cat_tipo_cliente: [],
			listar_cat_peps: [],
			listar_cat_edad_cliente: [],
			listar_cat_antiguedad_empresa: [],
			listar_cat_persona_bloqueada: [],
			listar_cat_actividad_economica: [],
			listar_cat_tipo_credito: [],
			listar_cat_numero_transacciones: [],
			listar_cat_monto_transacciones: [],
			listar_cat_monto_ingreso: [],
			listar_cat_sector_economico: [],
			listar_cat_autorizacion: [],
			listar_cat_cumplimiento: [],
			listar_cat_domicilio: [],
			listar_cat_actua_cuenta_propia: [],
			listar_cat_forma_pago: [],
			listar_cat_tipo_moneda: [],
			listar_cat_immueble_utilizado_domicilio: [],
			listar_cat_visita_ocular: [],
			listar_cat_mitigante: [],
			listar_cat_otro_riesgo: [],
			listar_cat_cambio_perfil_transaccional: [],
			listar_cat_alerta_emitida: [],
		};
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}, () => {
			this.valorTotal();
		}));
		
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		//this.ListarExpediente();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	save = () => {
		try {
			/*if (!FieldsJs.Field(this.state.fecha_ingreso)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo fecha de ingreso es requerido.",
				});
			}*/
			if (this.state.id_matriz_riesgo > 0) {
				MatrizRiesgoService.Modificar(this.state).then((response) => {
					this.close();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} else {
				MatrizRiesgoService.Agregar(this.state).then((response) => {
					this.close();
					this.props.RefreshList(response.data);
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		this.ListarUsuarioAcreditado();
		this.ListarMatrizRiesgo();
		
		console.log(this.props);
		this.setState({
			id_cat_nacionalidad: '',
			id_cat_estado_republica: '',
			id_cat_tipo_cliente: '',
			id_cat_peps: '',
			id_cat_edad_cliente: '',
			id_cat_antiguedad_empresa: '',
			id_cat_persona_bloqueada: '',
			id_cat_tipo_credito: '',
			id_cat_actividad_economica: '',
			id_cat_actua_cuenta_propia: '',
			id_cat_numero_transacciones: '',
			id_cat_monto_transacciones: '',
			id_cat_monto_ingreso: '',
			id_cat_cambio_perfil_transaccional: '',
			id_cat_tipo_moneda: '',
			id_cat_forma_pago: '',
			id_cat_domicilio: '',
			id_cat_visita_ocular: '',
			id_cat_otro_riesgo: '',
			id_cat_alerta_emitida: '',
			id_cat_mitigante: '',
			id_cat_autorizacion: '',
			id_cat_cumplimiento: '',
			ponderacion: '',
			observaciones: '',
			valor_total: 0,
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.ListarUsuarioAcreditado().then((response) => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		this.ListarMatrizRiesgo().then((response) => {
			this.setState({
				id_cat_nacionalidad: item.id_cat_nacionalidad.toString() || '',
				id_cat_estado_republica: item.id_cat_estado_republica || '',
				id_cat_tipo_cliente: item.id_cat_tipo_cliente || '',
				id_cat_peps: item.id_cat_peps.toString() || '',
				id_cat_edad_cliente: item.id_cat_edad_cliente || '',
				id_cat_antiguedad_empresa: item.id_cat_antiguedad_empresa || '',
				id_cat_persona_bloqueada: item.id_cat_persona_bloqueada.toString() || '',
				id_cat_tipo_credito: item.id_cat_tipo_credito || '',
				id_cat_actividad_economica: item.id_cat_actividad_economica || '',
				id_cat_actua_cuenta_propia: item.id_cat_actua_cuenta_propia.toString() || '',
				id_cat_numero_transacciones: item.id_cat_numero_transacciones || '',
				id_cat_monto_transacciones: item.id_cat_monto_transacciones || '',
				id_cat_monto_ingreso: item.id_cat_monto_ingreso || '',
				id_cat_cambio_perfil_transaccional: item.id_cat_cambio_perfil_transaccional.toString() || '',
				id_cat_tipo_moneda: item.id_cat_tipo_moneda.toString() || '',
				id_cat_forma_pago: item.id_cat_forma_pago || '',
				id_cat_domicilio: item.id_cat_domicilio || '',
				id_cat_visita_ocular: item.id_cat_visita_ocular.toString() || '',
				id_cat_otro_riesgo: item.id_cat_otro_riesgo || '',
				id_cat_alerta_emitida: item.id_cat_alerta_emitida.toString() || '',
				id_cat_mitigante: item.id_cat_mitigante || '',
				id_cat_autorizacion: item.id_cat_autorizacion || '',
				id_cat_cumplimiento: item.id_cat_cumplimiento.toString() || '',
				ponderacion: item.ponderacion || '',
				observaciones: item.observaciones || '',
				valor_total: item.valor_total || '',
			});
		});
		
		this.setState({
			id_matriz_riesgo: item.id_matriz_riesgo || '',
			activo: (item.activo === 1),
		});
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.ListarUsuarioAcreditado().then((response) => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		this.ListarMatrizRiesgo().then((response) => {
			this.setState({
				id_cat_nacionalidad: item.id_cat_nacionalidad.toString() || '',
				id_cat_estado_republica: item.id_cat_estado_republica || '',
				id_cat_tipo_cliente: item.id_cat_tipo_cliente || '',
				id_cat_peps: item.id_cat_peps.toString() || '',
				id_cat_edad_cliente: item.id_cat_edad_cliente || '',
				id_cat_antiguedad_empresa: item.id_cat_antiguedad_empresa || '',
				id_cat_persona_bloqueada: item.id_cat_persona_bloqueada.toString() || '',
				id_cat_tipo_credito: item.id_cat_tipo_credito || '',
				id_cat_actividad_economica: item.id_cat_actividad_economica || '',
				id_cat_actua_cuenta_propia: item.id_cat_actua_cuenta_propia.toString() || '',
				id_cat_numero_transacciones: item.id_cat_numero_transacciones || '',
				id_cat_monto_transacciones: item.id_cat_monto_transacciones || '',
				id_cat_monto_ingreso: item.id_cat_monto_ingreso || '',
				id_cat_cambio_perfil_transaccional: item.id_cat_cambio_perfil_transaccional.toString() || '',
				id_cat_tipo_moneda: item.id_cat_tipo_moneda.toString() || '',
				id_cat_forma_pago: item.id_cat_forma_pago || '',
				id_cat_domicilio: item.id_cat_domicilio || '',
				id_cat_visita_ocular: item.id_cat_visita_ocular.toString() || '',
				id_cat_otro_riesgo: item.id_cat_otro_riesgo || '',
				id_cat_alerta_emitida: item.id_cat_alerta_emitida.toString() || '',
				id_cat_mitigante: item.id_cat_mitigante || '',
				id_cat_autorizacion: item.id_cat_autorizacion || '',
				id_cat_cumplimiento: item.id_cat_cumplimiento.toString() || '',
				ponderacion: item.ponderacion || '',
				observaciones: item.observaciones || '',
				valor_total: item.valor_total || '',
			});
		})
		
		this.setState({
			id_matriz_riesgo: item.id_matriz_riesgo || '',
			activo: (item.activo === 1),
		});
		
		this.open();
	};
	
	ListarUsuarioAcreditado = () => {
		return new Promise((resolve) => {
			CatService.ListUsuarioAcreditado().then((response) => {
				this.setState({
					listar_usuario_acreditado: response.data
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_usuario_acreditado: []
				});
			});
		});
	}
	
	valorTotal = () => {
		let val = 0;
		
		for (let i = 0; i < this.state.listar_cat_nacionalidad.length; i++) {
			if (Number(this.state.id_cat_nacionalidad) === Number(this.state.listar_cat_nacionalidad[i].id_cat_nacionalidad)) {
				val += Number(this.state.listar_cat_nacionalidad[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_estado_republica.length; i++) {
			if (Number(this.state.id_cat_estado_republica) === Number(this.state.listar_cat_estado_republica[i].id_cat_estado_republica)) {
				val += Number(this.state.listar_cat_estado_republica[i].valor);
				
				for (let x = 0; x < this.state.listar_cat_zona_geografica.length; x++) {
					if (Number(this.state.listar_cat_estado_republica[i].id_cat_zona_geografica) === Number(this.state.listar_cat_zona_geografica[x].id_cat_zona_geografica)) {
						val += Number(this.state.listar_cat_zona_geografica[x].valor);
					}
				}
			}
		}
		for (let i = 0; i < this.state.listar_cat_tipo_cliente.length; i++) {
			if (Number(this.state.id_cat_tipo_cliente) === Number(this.state.listar_cat_tipo_cliente[i].id_cat_tipo_cliente)) {
				val += Number(this.state.listar_cat_tipo_cliente[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_peps.length; i++) {
			if (Number(this.state.id_cat_peps) === Number(this.state.listar_cat_peps[i].id_cat_peps)) {
				val += Number(this.state.listar_cat_peps[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_antiguedad_empresa.length; i++) {
			if (Number(this.state.id_cat_antiguedad_empresa) === Number(this.state.listar_cat_antiguedad_empresa[i].id_cat_antiguedad_empresa)) {
				val += Number(this.state.listar_cat_antiguedad_empresa[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_persona_bloqueada.length; i++) {
			if (Number(this.state.id_cat_persona_bloqueada) === Number(this.state.listar_cat_persona_bloqueada[i].id_cat_persona_bloqueada)) {
				val += Number(this.state.listar_cat_persona_bloqueada[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_tipo_credito.length; i++) {
			if (Number(this.state.id_cat_tipo_credito) === Number(this.state.listar_cat_tipo_credito[i].id_cat_tipo_credito)) {
				val += Number(this.state.listar_cat_tipo_credito[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_actividad_economica.length; i++) {
			if (Number(this.state.id_cat_actividad_economica) === Number(this.state.listar_cat_actividad_economica[i].id_cat_actividad_economica)) {
				val += Number(this.state.listar_cat_actividad_economica[i].valor);
				for (let o = 0; o < this.state.listar_cat_sector_economico.length; o++) {
					if (Number(this.state.listar_cat_actividad_economica[i].id_cat_sector_economico) === Number(this.state.listar_cat_sector_economico[o].id_cat_sector_economico)) {
						val += Number(this.state.listar_cat_sector_economico[o].valor);
					}
				}
			}
		}
		for (let i = 0; i < this.state.listar_cat_edad_cliente.length; i++) {
			if (Number(this.state.id_cat_edad_cliente) === Number(this.state.listar_cat_edad_cliente[i].id_cat_edad_cliente)) {
				val += Number(this.state.listar_cat_edad_cliente[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_actua_cuenta_propia.length; i++) {
			if (Number(this.state.id_cat_actua_cuenta_propia) === Number(this.state.listar_cat_actua_cuenta_propia[i].id_cat_actua_cuenta_propia)) {
				val += Number(this.state.listar_cat_actua_cuenta_propia[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_numero_transacciones.length; i++) {
			if (Number(this.state.id_cat_numero_transacciones) === Number(this.state.listar_cat_numero_transacciones[i].id_cat_numero_transacciones)) {
				val += Number(this.state.listar_cat_numero_transacciones[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_monto_transacciones.length; i++) {
			if (Number(this.state.id_cat_monto_transacciones) === Number(this.state.listar_cat_monto_transacciones[i].id_cat_monto_transacciones)) {
				val += Number(this.state.listar_cat_monto_transacciones[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_monto_ingreso.length; i++) {
			if (Number(this.state.id_cat_monto_ingreso) === Number(this.state.listar_cat_monto_ingreso[i].id_cat_monto_ingreso)) {
				val += Number(this.state.listar_cat_monto_ingreso[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_cambio_perfil_transaccional.length; i++) {
			if (Number(this.state.id_cat_cambio_perfil_transaccional) === Number(this.state.listar_cat_cambio_perfil_transaccional[i].id_cat_cambio_perfil_transaccional)) {
				val += Number(this.state.listar_cat_cambio_perfil_transaccional[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_tipo_moneda.length; i++) {
			if (Number(this.state.id_cat_tipo_moneda) === Number(this.state.listar_cat_tipo_moneda[i].id_cat_tipo_moneda)) {
				val += Number(this.state.listar_cat_tipo_moneda[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_forma_pago.length; i++) {
			if (Number(this.state.id_cat_forma_pago) === Number(this.state.listar_cat_forma_pago[i].id_cat_forma_pago)) {
				val += Number(this.state.listar_cat_forma_pago[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_domicilio.length; i++) {
			if (Number(this.state.id_cat_domicilio) === Number(this.state.listar_cat_domicilio[i].id_cat_domicilio)) {
				val += Number(this.state.listar_cat_domicilio[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_visita_ocular.length; i++) {
			if (Number(this.state.id_cat_visita_ocular) === Number(this.state.listar_cat_visita_ocular[i].id_cat_visita_ocular)) {
				val += Number(this.state.listar_cat_visita_ocular[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_otro_riesgo.length; i++) {
			if (Number(this.state.id_cat_otro_riesgo) === Number(this.state.listar_cat_otro_riesgo[i].id_cat_otro_riesgo)) {
				val += Number(this.state.listar_cat_otro_riesgo[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_alerta_emitida.length; i++) {
			if (Number(this.state.id_cat_alerta_emitida) === Number(this.state.listar_cat_alerta_emitida[i].id_cat_alerta_emitida)) {
				val += Number(this.state.listar_cat_alerta_emitida[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_mitigante.length; i++) {
			if (Number(this.state.id_cat_mitigante) === Number(this.state.listar_cat_mitigante[i].id_cat_mitigante)) {
				val -= Number(this.state.listar_cat_mitigante[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_autorizacion.length; i++) {
			if (Number(this.state.id_cat_autorizacion) === Number(this.state.listar_cat_autorizacion[i].id_cat_autorizacion)) {
				val -= Number(this.state.listar_cat_autorizacion[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_cumplimiento.length; i++) {
			if (Number(this.state.id_cat_cumplimiento) === Number(this.state.listar_cat_cumplimiento[i].id_cat_cumplimiento)) {
				val -= Number(this.state.listar_cat_cumplimiento[i].valor);
			}
		}
		
		val -= Number(this.state.ponderacion);
		
		this.setState({
			valor_total: val
		});
	}
	
	ListarMatrizRiesgo = () => {
		return new Promise((resolve) => {
			CatService.ListMatrizRiesgo().then((response) => {
				var res = response.data;
				this.setState({
					listar_cat_nacionalidad: res.cat_nacionalidad,
					listar_cat_peps: res.cat_peps,
					listar_cat_estado_republica: res.cat_estado_republica,
					listar_cat_edad_cliente: res.cat_edad_cliente,
					listar_cat_tipo_cliente: res.cat_tipo_cliente,
					listar_cat_antiguedad_empresa: res.cat_antiguedad_empresa,
					listar_cat_persona_bloqueada: res.cat_persona_bloqueada,
					listar_cat_actividad_economica: res.cat_actividad_economica,
					listar_cat_monto_transacciones: res.cat_monto_transacciones,
					listar_cat_monto_ingreso: res.cat_monto_ingreso,
					listar_cat_numero_transacciones: res.cat_numero_transacciones,
					listar_cat_tipo_credito: res.cat_tipo_credito,
					listar_cat_sector_economico: res.cat_sector_economico,
					listar_cat_autorizacion: res.cat_autorizacion,
					listar_cat_cumplimiento: res.cat_cumplimiento,
					listar_cat_domicilio: res.cat_domicilio,
					listar_cat_actua_cuenta_propia: res.cat_actua_cuenta_propia,
					listar_cat_forma_pago: res.cat_forma_pago,
					listar_cat_tipo_moneda: res.cat_tipo_moneda,
					listar_cat_immueble_utilizado_domicilio: res.cat_immueble_utilizado_domicilio,
					listar_cat_visita_ocular: res.cat_visita_ocular,
					listar_cat_mitigante: res.cat_mitigante,
					listar_cat_otro_riesgo: res.cat_otro_riesgo,
					listar_cat_cambio_perfil_transaccional: res.cat_cambio_perfil_transaccional,
					listar_cat_alerta_emitida: res.cat_alerta_emitida,
					listar_cat_zona_geografica: res.cat_zona_geografica,
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_cat_nacionalidad: [],
					listar_cat_peps: [],
					listar_cat_estado_republica: [],
					listar_cat_edad_cliente: [],
					listar_cat_tipo_cliente: [],
					listar_cat_antiguedad_empresa: [],
					listar_cat_persona_bloqueada: [],
					listar_cat_actividad_enconomica: [],
					listar_cat_tipo_credito: [],
					listar_cat_monto_transacciones: [],
					listar_cat_monto_ingreso: [],
					listar_cat_sector_economico: [],
					listar_cat_autorizacion: [],
					listar_cat_cumplimiento: [],
					listar_cat_domicilio: [],
					listar_cat_actua_cuenta_propia: [],
					listar_cat_forma_pago: [],
					listar_cat_tipo_moneda: [],
					listar_cat_immueble_utilizado_domicilio: [],
					listar_cat_visita_ocular: [],
					listar_cat_mitigante: [],
					listar_cat_otro_riesgo: [],
					listar_cat_cambio_perfil_transaccional: [],
					listar_cat_alerta_emitida: [],
				});
			});
		});
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_matriz_riesgo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Matriz de Riesgo'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								Captura la información del acreditado para obtener el nivel de riesgo
							</Grid>
							
							<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
								<div style={{height: '96%', background: '#EFF0F4', borderRadius: 6, padding: '10px'}}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'center'}>
											<div className={'h-100 vertical-inline'}>
												<div className={'v-center'} style={{fontWeight: 500}}>
													Acreditado
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_acreditado"
												label="Seleccionar Acreditado"
												value={this.state.id_acreditado}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_usuario_acreditado.map(option => (
													<option key={option.id_acreditado} value={option.id_acreditado}>
														{Number(option.id_cat_tipo_persona) === 1 ? option.nombre_completo : option.razon_social}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">Nacionalidad</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_nacionalidad"
													value={this.state.id_cat_nacionalidad}
													onChange={(e) => {
														this.handleChange(e);
													}}>
													{this.state.listar_cat_nacionalidad.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_nacionalidad.toString()}
															label={item.nacionalidad}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												InputLabelProps={{shrink: true}}
												name="id_cat_estado_republica"
												label="Estado de la República"
												value={this.state.id_cat_estado_republica}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_estado_republica.map(option => (
													<option key={option.id_cat_estado_republica} value={option.id_cat_estado_republica}>
														{option.estado_republica + ' - ' + option.zona_geografica}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												InputLabelProps={{shrink: true}}
												name="id_cat_tipo_cliente"
												label="Tipo de Cliente"
												value={this.state.id_cat_tipo_cliente}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_tipo_cliente.map(option => (
													<option key={option.id_cat_tipo_cliente} value={option.id_cat_tipo_cliente}>
														{option.tipo_cliente}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_edad_cliente"
												label="Edad"
												InputLabelProps={{shrink: true}}
												value={this.state.id_cat_edad_cliente}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_edad_cliente.map(option => (
													<option key={option.id_cat_edad_cliente} value={option.id_cat_edad_cliente}>
														{option.edad_cliente}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">Peps</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_peps"
													value={this.state.id_cat_peps}
													onChange={this.handleChange}>
													{this.state.listar_cat_peps.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_peps.toString()}
															label={item.peps}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_antiguedad_empresa"
												InputLabelProps={{shrink: true}}
												label="Antigüedad Empresa"
												value={this.state.id_cat_antiguedad_empresa}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_antiguedad_empresa.map(option => (
													<option key={option.id_cat_antiguedad_empresa} value={option.id_cat_antiguedad_empresa}>
														{option.antiguedad_empresa}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">Persona Bloqueada</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_persona_bloqueada"
													value={this.state.id_cat_persona_bloqueada}
													onChange={this.handleChange}>
													{this.state.listar_cat_persona_bloqueada.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_persona_bloqueada.toString()}
															label={item.persona_bloqueada}
														/>
													))}
												</RadioGroup>
											</FormControl>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												InputLabelProps={{shrink: true}}
												name="id_cat_tipo_credito"
												label="Tipo de Crédito"
												value={this.state.id_cat_tipo_credito}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_tipo_credito.map(option => (
													<option key={option.id_cat_tipo_credito} value={option.id_cat_tipo_credito}>
														{option.tipo_credito}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_actividad_economica"
												InputLabelProps={{shrink: true}}
												label="Actividad Económica"
												value={this.state.id_cat_actividad_economica}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_actividad_economica.map(option => (
													<option key={option.id_cat_actividad_economica} value={option.id_cat_actividad_economica}>
														{option.sector_economico + ' - ' + option.actividad_economica}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_numero_transacciones"
												InputLabelProps={{shrink: true}}
												label="Número de Transacciones"
												value={this.state.id_cat_numero_transacciones}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_numero_transacciones.map(option => (
													<option key={option.id_cat_numero_transacciones} value={option.id_cat_numero_transacciones}>
														{option.numero_transacciones}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">Actúa Cuenta Propia</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_actua_cuenta_propia"
													value={this.state.id_cat_actua_cuenta_propia}
													onChange={this.handleChange}>
													{this.state.listar_cat_actua_cuenta_propia.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_actua_cuenta_propia.toString()}
															label={item.actua_cuenta_propia}
														/>
													))}
												</RadioGroup>
											</FormControl>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_monto_transacciones"
												InputLabelProps={{shrink: true}}
												label="Monto de Transacciones"
												value={this.state.id_cat_monto_transacciones}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_monto_transacciones.map(option => (
													<option key={option.id_cat_monto_transacciones} value={option.id_cat_monto_transacciones}>
														{option.monto_transacciones}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">Cambio de Perfil Transaccional</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_cambio_perfil_transaccional"
													value={this.state.id_cat_cambio_perfil_transaccional}
													onChange={this.handleChange}>
													{this.state.listar_cat_cambio_perfil_transaccional.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_cambio_perfil_transaccional.toString()}
															label={item.cambio_perfil_transaccional}
														/>
													))}
												</RadioGroup>
											</FormControl>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_monto_ingreso"
												InputLabelProps={{shrink: true}}
												label="Monto de Ingreso"
												value={this.state.id_cat_monto_ingreso}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_monto_ingreso.map(option => (
													<option key={option.id_cat_monto_ingreso} value={option.id_cat_monto_ingreso}>
														{option.monto_ingreso}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">Tipo Moneda</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_tipo_moneda"
													value={this.state.id_cat_tipo_moneda}
													onChange={this.handleChange}>
													{this.state.listar_cat_tipo_moneda.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_tipo_moneda.toString()}
															label={item.tipo_moneda}
														/>
													))}
												</RadioGroup>
											</FormControl>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_forma_pago"
												InputLabelProps={{shrink: true}}
												label="Forma de Pago"
												value={this.state.id_cat_forma_pago}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_forma_pago.map(option => (
													<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
														{option.forma_pago}
													</option>
												))}
											</TextField>
										</Grid>
										
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_domicilio"
												InputLabelProps={{shrink: true}}
												label="Tipo de Domicilio"
												value={this.state.id_cat_domicilio}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_domicilio.map(option => (
													<option key={option.id_cat_domicilio} value={option.id_cat_domicilio}>
														{option.domicilio}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									
											<FormControl component="fieldset">
												<FormLabel component="legend">Visita Ocular</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_visita_ocular"
													value={this.state.id_cat_visita_ocular}
													onChange={this.handleChange}>
													{this.state.listar_cat_visita_ocular.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_visita_ocular.toString()}
															label={item.visita_ocular}
														/>
													))}
												</RadioGroup>
											</FormControl>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">Alerta Emitida</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_alerta_emitida"
													value={this.state.id_cat_alerta_emitida}
													onChange={this.handleChange}>
													{this.state.listar_cat_alerta_emitida.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_alerta_emitida.toString()}
															label={item.alerta_emitida}
														/>
													))}
												</RadioGroup>
											</FormControl>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_otro_riesgo"
												InputLabelProps={{shrink: true}}
												label="Seleccionar Otro Riesgo"
												value={this.state.id_cat_otro_riesgo}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_otro_riesgo.map(option => (
													<option key={option.id_cat_otro_riesgo} value={option.id_cat_otro_riesgo}>
														{option.otro_riesgo}
													</option>
												))}
											</TextField>
										</Grid>
									
									</Grid>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								<div style={{height: '96%', background: '#EFF0F4', borderRadius: 6, padding: '10px'}}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
											<div className={'h-90 vertical-inline'}>
												<div className={'v-center padding-10-T'} style={{fontWeight: 500}}>
													Mitigantes
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Divider></Divider>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_mitigante"
												label="Políticas y Procedimientos"
												InputLabelProps={{shrink: true}}
												value={this.state.id_cat_mitigante}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_mitigante.map(option => (
													<option key={option.id_cat_mitigante} value={option.id_cat_mitigante}>
														{option.mitigante}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_autorizacion"
												InputLabelProps={{shrink: true}}
												label="Autorizaciones"
												value={this.state.id_cat_autorizacion}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_autorizacion.map(option => (
													<option key={option.id_cat_autorizacion} value={option.id_cat_autorizacion}>
														{option.autorizacion}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
											<FormControl component="fieldset">
												<FormLabel component="legend">Cumplimiento</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_cumplimiento"
													value={this.state.id_cat_cumplimiento}
													onChange={this.handleChange}>
													{this.state.listar_cat_cumplimiento.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_cumplimiento.toString()}
															label={item.cumplimiento}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												margin="none"
												name="ponderacion"
												label="Ponderación del Administrador"
												type="text"
												fullWidth
												value={this.state.ponderacion}
												onChange={(e) => {
													this.handleChange(e);
												}}
												InputLabelProps={{shrink: true}}
												/*onKeyPress={EnteroSolo}*/
												inputProps={{maxLength: 50}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												margin="none"
												name="observaciones"
												label="Observaciones Generales"
												type="text"
												fullWidth
												multiline
												rows={4}
												value={this.state.observaciones}
												InputLabelProps={{shrink: true}}
												onChange={(e) => {
													this.handleChange(e);
												}}
												inputProps={{maxLength: 50}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<div className={'h-100 vertical-inline'}>
												<div className={'v-center padding-10-T px-14'} style={{fontWeight: 500}}>
													TOTAL
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<div className={'h-100 vertical-inline'}>
												<div className={'v-center px-25'} style={{fontWeight: 600}}>
													{this.state.valor_total}
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
											<div className={'h-100 vertical-inline'}>
												<div className={'v-center padding-15-T px-12'} style={{fontWeight: 500, color: 'rgba(0, 0, 0, 0.5)'}}>
													Elaboró reporte:
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
											<div className={'h-100 vertical-inline'}>
												<div className={'v-center px-14'} style={{fontWeight: 600}}>
													{this.Usr.nombre_completo}
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
											<br/>
										</Grid>
										
										
										<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
											<Button onClick={this.close} color="default" variant="contained">
												{'Cancelar'}
											</Button>
										</Grid>
										{ this.props.tipo !== 'view' ? (
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
												<Button onClick={this.save} color="primary" variant="contained">
													{this.props.tipo === 'add' ? 'Agregar':'Modificar'}
												</Button>
											</Grid>
										): null}
										
									</Grid>
								</div>
							</Grid>
						
						</Grid>
					
					</DialogContent>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalMatrizRiesgo.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalMatrizRiesgo;
