import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import {HowToVoteOutlined, CalendarTodayOutlined, DeviceHubOutlined, PinDropOutlined, VerifiedUser, PhoneOutlined, ImageAspectRatioOutlined, HdrWeakOutlined, HdrStrongOutlined,
	AlternateEmailOutlined,
	ArrowBack,
	GrainOutlined,
	PhoneIphoneOutlined,
	StreetviewOutlined,
	TurnedInNotOutlined
} from "@material-ui/icons"
import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {RutaService} from "../../../../services/_Sis/RutaService/RutaService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {ReclamacionesService} from "../../../../services/_Sis/ReclamacionesService/ReclamacionesService";
import {KeyboardDatePicker} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from "@material-ui/core/Typography";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ArchivosExpedientes from "./ArchivosExpedientes";
import SeccionExpedientes from "./SeccionExpedientes";
import {CONFIG} from "../../../../settings/Config/Config";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {Dropbox} from 'dropbox';
import Fab from "@material-ui/core/Fab";
import ModalValidacion from "./ModalValidacion";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import moment from "moment";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {ClienteService} from "../../../../services/_Sis/ClienteService/ClienteService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class ModalReclamaciones extends Component {
	
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_matriz_riesgo: (props.item || {}).id_matriz_riesgo,
			id_acreditado: '',
			
			id_cat_nacionalidad: '',
			id_cat_estado_republica: '',
			id_cat_tipo_cliente: '',
			id_cat_peps: '',
			id_cat_edad_cliente: '',
			id_cat_antiguedad_empresa: '',
			id_cat_persona_bloqueada: '',
			id_cat_tipo_credito: '',
			id_cat_actividad_economica: '',
			id_cat_actua_cuenta_propia: '',
			id_cat_numero_transacciones: '',
			id_cat_monto_transacciones: '',
			id_cat_monto_ingreso: '',
			id_cat_cambio_perfil_transaccional: '',
			id_cat_tipo_moneda: '',
			id_cat_forma_pago: '',
			id_cat_domicilio: '',
			id_cat_visita_ocular: '',
			id_cat_otro_riesgo: '',
			id_cat_alerta_emitida: '',
			id_cat_mitigante: '',
			id_cat_autorizacion: '',
			id_cat_cumplimiento: '',
			ponderacion: '',
			observaciones: '',
			valor_total: 0,
			
			activo: true,
			
			listar_usuario_acreditado: [],
			listar_cat_nacionalidad: [],
			listar_cat_estado_republica: [],
			listar_cat_tipo_cliente: [],
			listar_cat_peps: [],
			listar_cat_edad_cliente: [],
			listar_cat_antiguedad_empresa: [],
			listar_cat_persona_bloqueada: [],
			listar_cat_actividad_economica: [],
			listar_cat_tipo_credito: [],
			listar_cat_numero_transacciones: [],
			listar_cat_monto_transacciones: [],
			listar_cat_monto_ingreso: [],
			listar_cat_sector_economico: [],
			listar_cat_autorizacion: [],
			listar_cat_cumplimiento: [],
			listar_cat_domicilio: [],
			listar_cat_actua_cuenta_propia: [],
			listar_cat_forma_pago: [],
			listar_cat_tipo_moneda: [],
			listar_cat_immueble_utilizado_domicilio: [],
			listar_cat_visita_ocular: [],
			listar_cat_mitigante: [],
			listar_cat_otro_riesgo: [],
			listar_cat_cambio_perfil_transaccional: [],
			listar_cat_alerta_emitida: [],
			listar_cat_estado: [],
			listar_cat_municipio: [],
			listar_cat_monetario: [],
			fecha_reclamacion: null,
			fecha_atencion: null,
			fecha_federativa: null,
			fecha_resolucion: null,
		};
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}, () => {
			this.valorTotal();
		}));
		
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		//this.ListarExpediente();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	save = () => {
		try {
			/*if (!FieldsJs.Field(this.state.fecha_ingreso)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo fecha de ingreso es requerido.",
				});
			}*/
			if (this.state.id_matriz_riesgo > 0) {
				ReclamacionesService.Modificar(this.state).then((response) => {
					this.close();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} else {
				ReclamacionesService.Agregar(this.state).then((response) => {
					this.close();
					this.props.RefreshList(response.data);
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		this.ListarUsuarioAcreditado();
		this.ListarReclamaciones();
		
		console.log(this.props);
		this.setState({
			id_cat_nacionalidad: '',
			id_cat_estado_republica: '',
			id_cat_tipo_cliente: '',
			id_cat_peps: '',
			id_cat_edad_cliente: '',
			id_cat_antiguedad_empresa: '',
			id_cat_persona_bloqueada: '',
			id_cat_tipo_credito: '',
			id_cat_actividad_economica: '',
			id_cat_actua_cuenta_propia: '',
			id_cat_numero_transacciones: '',
			id_cat_monto_transacciones: '',
			id_cat_monto_ingreso: '',
			id_cat_cambio_perfil_transaccional: '',
			id_cat_tipo_moneda: '',
			id_cat_forma_pago: '',
			id_cat_domicilio: '',
			id_cat_visita_ocular: '',
			id_cat_otro_riesgo: '',
			id_cat_alerta_emitida: '',
			id_cat_mitigante: '',
			id_cat_autorizacion: '',
			id_cat_cumplimiento: '',
			ponderacion: '',
			observaciones: '',
			valor_total: 0,
			activo: true,
			listar_cat_monetario: [{id_cat_monetario: 1, monetario: 'SI'}, {id_cat_monetario: 2, monetario: 'NO'}],
			razon_social: 'Operadora Procea del Centro, S.A. de C.V., SOFOM, E.N.R.',
			sector: 'Sociedades Financieras de Objeto Múltiple E.N.R.',
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.ListarUsuarioAcreditado().then((response) => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		this.ListarReclamaciones().then((response) => {
			this.setState({
				id_cat_nacionalidad: item.id_cat_nacionalidad.toString() || '',
				id_cat_estado_republica: item.id_cat_estado_republica || '',
				id_cat_tipo_cliente: item.id_cat_tipo_cliente || '',
				id_cat_peps: item.id_cat_peps.toString() || '',
				id_cat_edad_cliente: item.id_cat_edad_cliente || '',
				id_cat_antiguedad_empresa: item.id_cat_antiguedad_empresa || '',
				id_cat_persona_bloqueada: item.id_cat_persona_bloqueada.toString() || '',
				id_cat_tipo_credito: item.id_cat_tipo_credito || '',
				id_cat_actividad_economica: item.id_cat_actividad_economica || '',
				id_cat_actua_cuenta_propia: item.id_cat_actua_cuenta_propia.toString() || '',
				id_cat_numero_transacciones: item.id_cat_numero_transacciones || '',
				id_cat_monto_transacciones: item.id_cat_monto_transacciones || '',
				id_cat_monto_ingreso: item.id_cat_monto_ingreso || '',
				id_cat_cambio_perfil_transaccional: item.id_cat_cambio_perfil_transaccional.toString() || '',
				id_cat_tipo_moneda: item.id_cat_tipo_moneda.toString() || '',
				id_cat_forma_pago: item.id_cat_forma_pago || '',
				id_cat_domicilio: item.id_cat_domicilio || '',
				id_cat_visita_ocular: item.id_cat_visita_ocular.toString() || '',
				id_cat_otro_riesgo: item.id_cat_otro_riesgo || '',
				id_cat_alerta_emitida: item.id_cat_alerta_emitida.toString() || '',
				id_cat_mitigante: item.id_cat_mitigante || '',
				id_cat_autorizacion: item.id_cat_autorizacion || '',
				id_cat_cumplimiento: item.id_cat_cumplimiento.toString() || '',
				ponderacion: item.ponderacion || '',
				observaciones: item.observaciones || '',
				valor_total: item.valor_total || '',
			});
		});
		
		this.setState({
			id_matriz_riesgo: item.id_matriz_riesgo || '',
			activo: (item.activo === 1),
		});
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.ListarUsuarioAcreditado().then((response) => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		this.ListarReclamaciones().then((response) => {
			this.setState({
				id_cat_nacionalidad: item.id_cat_nacionalidad.toString() || '',
				id_cat_estado_republica: item.id_cat_estado_republica || '',
				id_cat_tipo_cliente: item.id_cat_tipo_cliente || '',
				id_cat_peps: item.id_cat_peps.toString() || '',
				id_cat_edad_cliente: item.id_cat_edad_cliente || '',
				id_cat_antiguedad_empresa: item.id_cat_antiguedad_empresa || '',
				id_cat_persona_bloqueada: item.id_cat_persona_bloqueada.toString() || '',
				id_cat_tipo_credito: item.id_cat_tipo_credito || '',
				id_cat_actividad_economica: item.id_cat_actividad_economica || '',
				id_cat_actua_cuenta_propia: item.id_cat_actua_cuenta_propia.toString() || '',
				id_cat_numero_transacciones: item.id_cat_numero_transacciones || '',
				id_cat_monto_transacciones: item.id_cat_monto_transacciones || '',
				id_cat_monto_ingreso: item.id_cat_monto_ingreso || '',
				id_cat_cambio_perfil_transaccional: item.id_cat_cambio_perfil_transaccional.toString() || '',
				id_cat_tipo_moneda: item.id_cat_tipo_moneda.toString() || '',
				id_cat_forma_pago: item.id_cat_forma_pago || '',
				id_cat_domicilio: item.id_cat_domicilio || '',
				id_cat_visita_ocular: item.id_cat_visita_ocular.toString() || '',
				id_cat_otro_riesgo: item.id_cat_otro_riesgo || '',
				id_cat_alerta_emitida: item.id_cat_alerta_emitida.toString() || '',
				id_cat_mitigante: item.id_cat_mitigante || '',
				id_cat_autorizacion: item.id_cat_autorizacion || '',
				id_cat_cumplimiento: item.id_cat_cumplimiento.toString() || '',
				ponderacion: item.ponderacion || '',
				observaciones: item.observaciones || '',
				valor_total: item.valor_total || '',
			});
		})
		
		this.setState({
			id_matriz_riesgo: item.id_matriz_riesgo || '',
			activo: (item.activo === 1),
		});
		
		this.open();
	};
	
	ListarUsuarioAcreditado = () => {
		return new Promise((resolve) => {
			CatService.ListUsuarioAcreditado().then((response) => {
				this.setState({
					listar_usuario_acreditado: response.data
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_usuario_acreditado: []
				});
			});
		});
	}
	
	valorTotal = () => {
		let val = 0;
		
		for (let i = 0; i < this.state.listar_cat_nacionalidad.length; i++) {
			if (Number(this.state.id_cat_nacionalidad) === Number(this.state.listar_cat_nacionalidad[i].id_cat_nacionalidad)) {
				val += Number(this.state.listar_cat_nacionalidad[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_estado_republica.length; i++) {
			if (Number(this.state.id_cat_estado_republica) === Number(this.state.listar_cat_estado_republica[i].id_cat_estado_republica)) {
				val += Number(this.state.listar_cat_estado_republica[i].valor);
				
				for (let x = 0; x < this.state.listar_cat_zona_geografica.length; x++) {
					if (Number(this.state.listar_cat_estado_republica[i].id_cat_zona_geografica) === Number(this.state.listar_cat_zona_geografica[x].id_cat_zona_geografica)) {
						val += Number(this.state.listar_cat_zona_geografica[x].valor);
					}
				}
			}
		}
		for (let i = 0; i < this.state.listar_cat_tipo_cliente.length; i++) {
			if (Number(this.state.id_cat_tipo_cliente) === Number(this.state.listar_cat_tipo_cliente[i].id_cat_tipo_cliente)) {
				val += Number(this.state.listar_cat_tipo_cliente[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_peps.length; i++) {
			if (Number(this.state.id_cat_peps) === Number(this.state.listar_cat_peps[i].id_cat_peps)) {
				val += Number(this.state.listar_cat_peps[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_antiguedad_empresa.length; i++) {
			if (Number(this.state.id_cat_antiguedad_empresa) === Number(this.state.listar_cat_antiguedad_empresa[i].id_cat_antiguedad_empresa)) {
				val += Number(this.state.listar_cat_antiguedad_empresa[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_persona_bloqueada.length; i++) {
			if (Number(this.state.id_cat_persona_bloqueada) === Number(this.state.listar_cat_persona_bloqueada[i].id_cat_persona_bloqueada)) {
				val += Number(this.state.listar_cat_persona_bloqueada[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_tipo_credito.length; i++) {
			if (Number(this.state.id_cat_tipo_credito) === Number(this.state.listar_cat_tipo_credito[i].id_cat_tipo_credito)) {
				val += Number(this.state.listar_cat_tipo_credito[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_actividad_economica.length; i++) {
			if (Number(this.state.id_cat_actividad_economica) === Number(this.state.listar_cat_actividad_economica[i].id_cat_actividad_economica)) {
				val += Number(this.state.listar_cat_actividad_economica[i].valor);
				for (let o = 0; o < this.state.listar_cat_sector_economico.length; o++) {
					if (Number(this.state.listar_cat_actividad_economica[i].id_cat_sector_economico) === Number(this.state.listar_cat_sector_economico[o].id_cat_sector_economico)) {
						val += Number(this.state.listar_cat_sector_economico[o].valor);
					}
				}
			}
		}
		for (let i = 0; i < this.state.listar_cat_edad_cliente.length; i++) {
			if (Number(this.state.id_cat_edad_cliente) === Number(this.state.listar_cat_edad_cliente[i].id_cat_edad_cliente)) {
				val += Number(this.state.listar_cat_edad_cliente[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_actua_cuenta_propia.length; i++) {
			if (Number(this.state.id_cat_actua_cuenta_propia) === Number(this.state.listar_cat_actua_cuenta_propia[i].id_cat_actua_cuenta_propia)) {
				val += Number(this.state.listar_cat_actua_cuenta_propia[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_numero_transacciones.length; i++) {
			if (Number(this.state.id_cat_numero_transacciones) === Number(this.state.listar_cat_numero_transacciones[i].id_cat_numero_transacciones)) {
				val += Number(this.state.listar_cat_numero_transacciones[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_monto_transacciones.length; i++) {
			if (Number(this.state.id_cat_monto_transacciones) === Number(this.state.listar_cat_monto_transacciones[i].id_cat_monto_transacciones)) {
				val += Number(this.state.listar_cat_monto_transacciones[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_monto_ingreso.length; i++) {
			if (Number(this.state.id_cat_monto_ingreso) === Number(this.state.listar_cat_monto_ingreso[i].id_cat_monto_ingreso)) {
				val += Number(this.state.listar_cat_monto_ingreso[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_cambio_perfil_transaccional.length; i++) {
			if (Number(this.state.id_cat_cambio_perfil_transaccional) === Number(this.state.listar_cat_cambio_perfil_transaccional[i].id_cat_cambio_perfil_transaccional)) {
				val += Number(this.state.listar_cat_cambio_perfil_transaccional[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_tipo_moneda.length; i++) {
			if (Number(this.state.id_cat_tipo_moneda) === Number(this.state.listar_cat_tipo_moneda[i].id_cat_tipo_moneda)) {
				val += Number(this.state.listar_cat_tipo_moneda[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_forma_pago.length; i++) {
			if (Number(this.state.id_cat_forma_pago) === Number(this.state.listar_cat_forma_pago[i].id_cat_forma_pago)) {
				val += Number(this.state.listar_cat_forma_pago[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_domicilio.length; i++) {
			if (Number(this.state.id_cat_domicilio) === Number(this.state.listar_cat_domicilio[i].id_cat_domicilio)) {
				val += Number(this.state.listar_cat_domicilio[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_visita_ocular.length; i++) {
			if (Number(this.state.id_cat_visita_ocular) === Number(this.state.listar_cat_visita_ocular[i].id_cat_visita_ocular)) {
				val += Number(this.state.listar_cat_visita_ocular[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_otro_riesgo.length; i++) {
			if (Number(this.state.id_cat_otro_riesgo) === Number(this.state.listar_cat_otro_riesgo[i].id_cat_otro_riesgo)) {
				val += Number(this.state.listar_cat_otro_riesgo[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_alerta_emitida.length; i++) {
			if (Number(this.state.id_cat_alerta_emitida) === Number(this.state.listar_cat_alerta_emitida[i].id_cat_alerta_emitida)) {
				val += Number(this.state.listar_cat_alerta_emitida[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_mitigante.length; i++) {
			if (Number(this.state.id_cat_mitigante) === Number(this.state.listar_cat_mitigante[i].id_cat_mitigante)) {
				val -= Number(this.state.listar_cat_mitigante[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_autorizacion.length; i++) {
			if (Number(this.state.id_cat_autorizacion) === Number(this.state.listar_cat_autorizacion[i].id_cat_autorizacion)) {
				val -= Number(this.state.listar_cat_autorizacion[i].valor);
			}
		}
		for (let i = 0; i < this.state.listar_cat_cumplimiento.length; i++) {
			if (Number(this.state.id_cat_cumplimiento) === Number(this.state.listar_cat_cumplimiento[i].id_cat_cumplimiento)) {
				val -= Number(this.state.listar_cat_cumplimiento[i].valor);
			}
		}
		
		val -= Number(this.state.ponderacion);
		
		this.setState({
			valor_total: val
		});
	}
	
	ListarReclamaciones = () => {
		return new Promise((resolve) => {
			CatService.ListReclamaciones().then((response) => {
				var res = response.data;
				this.setState({
					listar_cat_nacionalidad: res.cat_nacionalidad,
					listar_cat_peps: res.cat_peps,
					listar_cat_estado_republica: res.cat_estado_republica,
					listar_cat_edad_cliente: res.cat_edad_cliente,
					listar_cat_tipo_cliente: res.cat_tipo_cliente,
					listar_cat_antiguedad_empresa: res.cat_antiguedad_empresa,
					listar_cat_persona_bloqueada: res.cat_persona_bloqueada,
					listar_cat_actividad_economica: res.cat_actividad_economica,
					listar_cat_monto_transacciones: res.cat_monto_transacciones,
					listar_cat_monto_ingreso: res.cat_monto_ingreso,
					listar_cat_numero_transacciones: res.cat_numero_transacciones,
					listar_cat_tipo_credito: res.cat_tipo_credito,
					listar_cat_sector_economico: res.cat_sector_economico,
					listar_cat_autorizacion: res.cat_autorizacion,
					listar_cat_cumplimiento: res.cat_cumplimiento,
					listar_cat_domicilio: res.cat_domicilio,
					listar_cat_actua_cuenta_propia: res.cat_actua_cuenta_propia,
					listar_cat_forma_pago: res.cat_forma_pago,
					listar_cat_tipo_moneda: res.cat_tipo_moneda,
					listar_cat_immueble_utilizado_domicilio: res.cat_immueble_utilizado_domicilio,
					listar_cat_visita_ocular: res.cat_visita_ocular,
					listar_cat_mitigante: res.cat_mitigante,
					listar_cat_otro_riesgo: res.cat_otro_riesgo,
					listar_cat_cambio_perfil_transaccional: res.cat_cambio_perfil_transaccional,
					listar_cat_alerta_emitida: res.cat_alerta_emitida,
					listar_cat_zona_geografica: res.cat_zona_geografica,
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_cat_nacionalidad: [],
					listar_cat_peps: [],
					listar_cat_estado_republica: [],
					listar_cat_edad_cliente: [],
					listar_cat_tipo_cliente: [],
					listar_cat_antiguedad_empresa: [],
					listar_cat_persona_bloqueada: [],
					listar_cat_actividad_enconomica: [],
					listar_cat_tipo_credito: [],
					listar_cat_monto_transacciones: [],
					listar_cat_monto_ingreso: [],
					listar_cat_sector_economico: [],
					listar_cat_autorizacion: [],
					listar_cat_cumplimiento: [],
					listar_cat_domicilio: [],
					listar_cat_actua_cuenta_propia: [],
					listar_cat_forma_pago: [],
					listar_cat_tipo_moneda: [],
					listar_cat_immueble_utilizado_domicilio: [],
					listar_cat_visita_ocular: [],
					listar_cat_mitigante: [],
					listar_cat_otro_riesgo: [],
					listar_cat_cambio_perfil_transaccional: [],
					listar_cat_alerta_emitida: [],
				});
			});
		});
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_matriz_riesgo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Reclamación'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{fontWeight: 500, textAlign: 'center', color: '#3f51b5'}}>
								TRIMESTRE A INFORMAR: 4t. Trimestre OCTUBRE - DICIEMBRE del año 2023.
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div style={{height: '96%', background: '#EFF0F4', borderRadius: 6, padding: '15px'}}>
									<Grid container spacing={2}>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												margin="none"
												name="razon_social"
												label="Denominación o Razón Social"
												type="text"
												fullWidth
												value={this.state.razon_social}
												InputLabelProps={{shrink: true}}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												margin="none"
												name="sector"
												label="Sector"
												type="text"
												fullWidth
												value={this.state.sector}
												InputLabelProps={{shrink: true}}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												margin="none"
												name="numero"
												label="Número de Reclamaciones"
												type="text"
												fullWidth
												value={this.state.numero}
												InputLabelProps={{shrink: true}}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												margin="none"
												name="folio_atencion"
												label="No. de Folio de Atención"
												type="text"
												fullWidth
												value={this.state.folio_atencion}
												InputLabelProps={{shrink: true}}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_acreditado"
												label="Estado Concluido / Pendiente"
												value={this.state.id_acreditado}
											>
												<option key={0} value={''}>&nbsp;</option>
												<option key={1} value={1}>{'Concluido'}</option>
												<option key={2} value={2}>{'Pendiene'}</option>
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<CalendarTodayOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<KeyboardDatePicker
														fullWidth
														clearable
														format={'dd/MM/yyyy'}
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														label="Fecha Reclamación"
														value={this.state.fecha_reclamacion}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_reclamacion');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<CalendarTodayOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<KeyboardDatePicker
														fullWidth
														clearable
														format={'dd/MM/yyyy'}
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														label="Fecha Atención"
														value={this.state.fecha_atencion}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_atencion');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<CalendarTodayOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<KeyboardDatePicker
														fullWidth
														clearable
														format={'dd/MM/yyyy'}
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														label="Fecha Federativa"
														value={this.state.fecha_federativa}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_federativa');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField type={'text'} fullWidth name="codigo_postal"
													           onChange={this.handleChange}
													           label="Código Postal" autoComplete={'off'}
													           value={this.state.codigo_postal}
													           required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrWeakOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={(e) => {
															this.handleChange(e);
															this.ListMunicipio(e);
														}}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_estado"
														label="Estado"
														value={this.state.id_cat_estado}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_estado.map(option => (
															<option key={option.id_cat_estado}
															        value={option.id_cat_estado}>
																{option.estado}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={this.handleChange}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_municipio"
														label="Municipio"
														value={this.state.id_cat_municipio}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												margin="none"
												name="localidad"
												label="Localidad"
												type="text"
												fullWidth
												value={this.state.localidad}
												onChange={(e) => {
													this.handleChange(e);
												}}
												InputLabelProps={{shrink: true}}
												/*onKeyPress={EnteroSolo}*/
												inputProps={{maxLength: 50}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												margin="none"
												name="colonia"
												label="Colonia"
												type="text"
												fullWidth
												value={this.state.colonia}
												onChange={(e) => {
													this.handleChange(e);
												}}
												InputLabelProps={{shrink: true}}
												/*onKeyPress={EnteroSolo}*/
												inputProps={{maxLength: 50}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={this.handleChange}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_municipio"
														label="Medio de recepción o canal"
														value={this.state.id_cat_municipio}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={this.handleChange}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_municipio"
														label="Nivel de atención o contacto"
														value={this.state.id_cat_municipio}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={this.handleChange}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_municipio"
														label="Posible Robo de Identidad (PORI)"
														value={this.state.id_cat_municipio}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<CalendarTodayOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<KeyboardDatePicker
														fullWidth
														clearable
														format={'dd/MM/yyyy'}
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														label="Fecha de Resolución"
														value={this.state.fecha_resolucion}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_resolucion');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={this.handleChange}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_municipio"
														label="Sentido de la Resolución"
														value={this.state.id_cat_municipio}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<CalendarTodayOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<KeyboardDatePicker
														fullWidth
														clearable
														format={'dd/MM/yyyy'}
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														label="Fecha de Notificación al Usuario"
														value={this.state.fecha_resolucion}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_resolucion');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={this.handleChange}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_municipio"
														label="Tipo de Persona"
														value={this.state.id_cat_municipio}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														select
														fullWidth
														onChange={this.handleChange}
														SelectProps={{
															native: true,
															MenuProps: {},
														}}
														
														name="id_cat_municipio"
														label="Sexo"
														value={this.state.id_cat_municipio}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_edad_cliente"
												label="Edad"
												InputLabelProps={{shrink: true}}
												value={this.state.id_cat_edad_cliente}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_edad_cliente.map(option => (
													<option key={option.id_cat_edad_cliente} value={option.id_cat_edad_cliente}>
														{option.edad_cliente}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_edad_cliente"
												label="Producto y/o Servicio (en su caso)"
												InputLabelProps={{shrink: true}}
												value={this.state.id_cat_edad_cliente}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_edad_cliente.map(option => (
													<option key={option.id_cat_edad_cliente} value={option.id_cat_edad_cliente}>
														{option.edad_cliente}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												name="id_cat_edad_cliente"
												label="Causa"
												InputLabelProps={{shrink: true}}
												value={this.state.id_cat_edad_cliente}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_edad_cliente.map(option => (
													<option key={option.id_cat_edad_cliente} value={option.id_cat_edad_cliente}>
														{option.edad_cliente}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<FormControl component="fieldset">
												<FormLabel component="legend">¿El asunto es monetario?</FormLabel>
												<RadioGroup
													row aria-label="position"
													name="id_cat_monetario"
													value={this.state.id_cat_monetario}
													onChange={(e) => {
														this.handleChange(e);
													}}>
													{this.state.listar_cat_monetario.map((item, key) => (
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															key={key}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={item.id_cat_monetario.toString()}
															label={item.monetario}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Grid>
										
										{/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											Los siguientes datos aplican si el medio de recepción es:
										</Grid>*/}
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												margin="none"
												name="colonia"
												label="Folio Institución (no requerido)"
												type="text"
												fullWidth
												value={this.state.colonia}
												onChange={(e) => {
													this.handleChange(e);
												}}
												InputLabelProps={{shrink: true}}
												/*onKeyPress={EnteroSolo}*/
												inputProps={{maxLength: 50}}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											
											<TextField
												className={'margin-0'}
												select
												fullWidth
												margin="dense"
												onChange={(e) => {
													this.handleChange(e);
												}}
												disabled={this.state.tipo === 'view'}
												SelectProps={{
													native: true,
													MenuProps: {},
												}}
												required
												InputLabelProps={{shrink: true}}
												name="id_cat_tipo_cliente"
												label="Reversa de Folio CONDUSEF"
												value={this.state.id_cat_tipo_cliente}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_cat_tipo_cliente.map(option => (
													<option key={option.id_cat_tipo_cliente} value={option.id_cat_tipo_cliente}>
														{option.tipo_cliente}
													</option>
												))}
											</TextField>
										</Grid>
										
										<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
											<Button onClick={this.close} color="default" variant="contained">
												{'Cancelar'}
											</Button>
										</Grid>
										
										<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'right'}>
											<Button onClick={this.save} color="primary" variant="contained">
												{this.props.tipo === 'add' ? 'Agregar':'Modificar'}
											</Button>
										</Grid>
									
									</Grid>
								</div>
							</Grid>
						
						</Grid>
					
					</DialogContent>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalReclamaciones.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalReclamaciones;
