import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {ClienteService} from "../../../../services/_Sis/ClienteService/ClienteService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {
	AddOutlined,
	AlternateEmailOutlined,
	CancelOutlined,
	DeleteOutlined,
	EditOutlined,
	GrainOutlined,
	HdrStrongOutlined,
	HdrWeakOutlined,
	HowToVoteOutlined,
	ImageAspectRatioOutlined,
	PhoneIphoneOutlined,
	PhoneOutlined,
	PinDropOutlined,
	SaveOutlined,
	StreetviewOutlined,
	TurnedInNotOutlined,
	Description,
	Person
} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from "../../../../assets/img/icons/contacto_tarjeta.svg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";

function Perso(props) {
	return null;
}

Perso.propTypes = {className: PropTypes.string};

class ModalCliente extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			//Cliente
			id_acreditado: (props.item || {}).id_acreditado,
			id_cat_tipo_persona: '',
			tipo_persona: 'fisica',
			id_empresa: '',
			nombre_comercial: '',
			razon_social: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			correo_electronico: '',
			celular: '',
			telefono: '',
			rfc: '',
			activo: true,
			receptor_factura: false,
			
			//Direccion
			id_acreditado_direccion: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			apoderado_legal: '',
			id_cat_pais: '',
			id_cat_regimen_fiscal: '',
			
			cat_estado: [],
			cat_municipio: [],
			
			
			contacto_nombre: '',
			contacto_apellido_paterno: '',
			contacto_apellido_materno: '',
			contacto_correo_electronico: '',
			contacto_celular: '',
			is_socio: false,
			
			lista_contactos: [],
			cat_pais: [],
			cat_regimen_fiscal: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			this.ListEstado();
			
			if (this.state.id_cat_estado > 0)
				this.ListMunicipio(null, this.state.id_cat_estado);
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	ListPaisFac = () => {
		return new Promise((resolve, reject) => {
			CatService.ListPais().then((response) => {
				this.setState({
					cat_pais: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	ListRegimenFiscal = (tipo_persona) => {
		return new Promise((resolve, reject) => {
			CatService.ListRegimenFiscal(tipo_persona).then((response) => {
				this.setState({
					cat_regimen_fiscal: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	 save = () => {
		if (this.state.id_acreditado > 0) {
			ClienteService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ClienteService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		
		let tipo_persona = 'fisica';
		if (item.id_cat_tipo_persona === 2) {
			tipo_persona = 'moral';
		}
		
		this.ListRegimenFiscal(item.id_cat_tipo_persona ? item.id_cat_tipo_persona : 1);
		
		this.ListPaisFac().then((response) => {
			this.setState({
				id_cat_pais: 401,
			});
		});
		
		this.setState({
			id_acreditado: '',
			id_cat_tipo_persona: '',
			tipo_persona: tipo_persona,
			id_empresa: '',
			nombre_comercial: '',
			razon_social: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			correo_electronico: '',
			celular: '',
			telefono: '',
			id_cat_regimen_fiscal: '',
			id_cat_pais: '',
			rfc: '',
			curp: '',
			receptor_factura: false,
			apoderado_legal: '',
			activo: true,
			
			//Direccion
			id_acreditado_direccion: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			is_socio: false,
			
			lista_contactos: []
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		let tipo_persona = 'fisica';
		if (item.id_cat_tipo_persona === 2) {
			tipo_persona = 'moral';
		}
		
		this.ListRegimenFiscal(item.id_cat_tipo_persona ? item.id_cat_tipo_persona : 1).then((response) => {
			this.setState({
				id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || ''
			});
		});
		
		this.ListPaisFac(item.id_cat_tipo_persona ? item.id_cat_tipo_persona : 1).then((response) => {
			this.setState({
				id_cat_pais: item.id_cat_pais || ''
			});
		});
		
		this.setState({
			id_acreditado: item.id_acreditado || '',
			id_cat_tipo_persona: item.id_cat_tipo_persona || '',
			tipo_persona: tipo_persona,
			id_empresa: item.id_empresa || '',
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			correo_electronico: item.correo_electronico || '',
			celular: item.celular || '',
			telefono: item.telefono || '',
			rfc: item.rfc || '',
			curp: item.curp || '',
			receptor_factura: (Number(item.receptor_factura) === 1),
			apoderado_legal: item.apoderado_legal || '',
			is_socio: Number(item.is_socio) === 1,
			activo: Number(item.activo) === 1,
			
			//Direccion
			id_acreditado_direccion: item.id_acreditado_direccion || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || '',
			registro_identidad_fiscal: item.registro_identidad_fiscal || '',
			id_cat_pais: item.id_cat_pais || '',
			lista_contactos: item.cliente_contactos || [],
		});
		this.open();
	};
	
	edit_contacto = (value, key) => {
		console.log("edit_contacto",value);
		try {
			
			if (!value.nombre) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Nombre del contacto.'
				})
			}
			
			if (!value.apellido_paterno) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Apellido Paterno del contacto.'
				})
			}
			
			if (!(value.correo_electronico)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Correo electronico del contacto.'
				})
			}
			
			if (!value.celular) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Celular del contacto.'
				})
			}
			
			this.setState({
				key: key,
				contacto_nombre: value.nombre,
				contacto_apellido_paterno: value.apellido_paterno,
				contacto_apellido_materno: value.apellido_materno,
				contacto_correo_electronico: value.correo_electronico,
				contacto_celular: value.celular
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			contacto_nombre:'',
			contacto_apellido_paterno:'',
			contacto_apellido_materno:'',
			contacto_correo_electronico:'',
			contacto_celular:''
			
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el contacto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.lista_contactos);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						lista_contactos: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	add_contacto = () => {
		
		try {
			
			if (!this.state.contacto_nombre) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Nombre del contacto.'
				})
			}
			
			if (!this.state.contacto_apellido_paterno) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Apellido Paterno del contacto.'
				})
			}
			
			if (!(this.state.contacto_correo_electronico)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Correo electronico del contacto.'
				})
			}
			
			if (!this.state.contacto_celular) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Celular del contacto.'
				})
			}
			
			let lista_contactos = FieldsJs.Copy(this.state.lista_contactos || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_contactos.length; i++) {
					let item = lista_contactos[i];
					if (this.state.key === i) {
						item.nombre = this.state.contacto_nombre || null;
						item.apellido_paterno = this.state.contacto_apellido_paterno || null;
						item.apellido_materno = this.state.contacto_apellido_materno || null;
						item.correo_electronico = this.state.contacto_correo_electronico || '';
						item.celular = this.state.contacto_celular || '';
					}
				}
			} else {
				let item;
				item = {
					nombre: this.state.contacto_nombre || null,
					apellido_paterno: this.state.contacto_apellido_paterno || null,
					apellido_materno: this.state.contacto_apellido_materno || null,
					correo_electronico: this.state.contacto_correo_electronico || '',
					celular: this.state.contacto_celular || ''
				};
				lista_contactos.push(item);
			}
			
			this.setState({
				key: null,
				contacto_nombre:'',
				contacto_apellido_paterno:'',
				contacto_apellido_materno:'',
				contacto_correo_electronico:'',
				contacto_celular:'',
				
				lista_contactos: lista_contactos
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	view = () => {
		const {item} = this.props;
		
		let tipo_persona = 'fisica';
		if (item.id_cat_tipo_persona === 2) {
			tipo_persona = 'moral';
		}
		
		this.ListRegimenFiscal(item.id_cat_tipo_persona ? item.id_cat_tipo_persona : 1).then((response) => {
			this.setState({
				id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || ''
			});
		});
		
		this.ListPaisFac(item.id_cat_tipo_persona ? item.id_cat_tipo_persona : 1).then((response) => {
			this.setState({
				id_cat_pais: item.id_cat_pais || ''
			});
		});
		
		this.setState({
			id_acreditado: item.id_acreditado || '',
			id_cat_tipo_persona: item.id_cat_tipo_persona || '',
			tipo_persona: item.id_cat_tipo_persona === 1 ? 'fisica' : 'moral',
			id_empresa: item.id_empresa || '',
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			correo_electronico: item.correo_electronico || '',
			celular: item.celular || '',
			telefono: item.telefono || '',
			rfc: item.rfc || '',
			curp: item.curp || '',
			receptor_factura: (Number(item.receptor_factura) === 1),
			apoderado_legal: item.apoderado_legal || '',
			is_socio: Number(item.is_socio) === 1,
			activo: Number(item.activo) === 1,
			
			//Direccion
			id_acreditado_direccion: item.id_acreditado_direccion || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			regimen_fiscal: item.regimen_fiscal || '',
			id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || '',
			registro_identidad_fiscal: item.registro_identidad_fiscal || '',
			id_cat_pais: item.id_cat_pais || '',
			lista_contactos: item.cliente_contactos || [],
		});
		this.open();
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_acreditado}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Acreditado'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							{/*{this.state.tipo_persona}<br/>
							{this.state.id_cat_tipo_persona}<br/>
							{this.state.razon_social}<br/>*/}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<RadioGroup row aria-label="position" name="tipo_persona"
								            value={this.state.tipo_persona}
								            onChange={(e) => {
									            this.handleChange(e);
									            this.ListRegimenFiscal(e.target.value === 'fisica' ? 1 : 2);
								            }}
								>
									<FormControlLabel
										labelPlacement="end"
										control={<Radio color="primary"/>}
										value={'fisica'}
										label="Física"
										disabled={this.state.tipo === 'view'}
									/>
									<FormControlLabel
										labelPlacement="end"
										control={<Radio color="primary"/>}
										value={'moral'}
										label="Moral"
										disabled={this.state.tipo === 'view'}
									/>
								</RadioGroup>
							</Grid>
							
							<Grid item className={'text-right'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<div className={'w-100 text-right'}>
									<FormControlLabel
										label={'¿El acreditado es receptor de factura?'}
										labelPlacement="start"
										control={
											<Checkbox
												type="checkbox" name='receptor_factura'
												checked={this.state.receptor_factura}
												onChange={this.handleChange}
												value="receptor_factura"
												color="primary"
												disabled={this.state.tipo === 'view'}/>
										}
									/>
								</div>
							
							</Grid>
							
							{this.state.tipo_persona === 'moral' ? (
								<Fragment>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={5} lg={5} xl={5}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="nombre_comercial"
												           onChange={this.handleChange}
												           label="Nombre comercial" autoComplete={'off'}
												           value={this.state.nombre_comercial}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
								
								</Fragment>
							) : ''}
							
							
							{this.state.tipo_persona === 'moral' ? (
								<Fragment>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="razon_social"
												           onChange={this.handleChange}
												           label="Razón social" autoComplete={'off'}
												           value={this.state.razon_social}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
								
								</Fragment>
							) : ''}
							
							{this.state.tipo_persona === 'fisica' ? (
								<Fragment>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="nombre"
												           onChange={this.handleChange}
												           label="Nombre" autoComplete={'off'}
												           value={this.state.nombre}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="apellido_paterno"
												           onChange={this.handleChange}
												           label="Apellido Paterno" autoComplete={'off'}
												           value={this.state.apellido_paterno}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="apellido_materno"
												           onChange={this.handleChange}
												           label="Apellido Materno" autoComplete={'off'}
												           value={this.state.apellido_materno}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
								
								</Fragment>
							) : ''}
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AlternateEmailOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="correo_electronico"
										           onChange={this.handleChange}
										           label="Correo electrónico" autoComplete={'off'}
										           value={this.state.correo_electronico}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="telefono"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           label="Teléfono" autoComplete={'off'}
										           value={this.state.telefono}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneIphoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="celular"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           label="Celular" autoComplete={'off'}
										           value={this.state.celular}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="curp"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 18}}
										           label="CURP" autoComplete={'off'}
										           value={this.state.curp}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="rfc"
										           onChange={this.handleChange}
										           inputProps={{maxLength: this.state.tipo_persona === 'fisica'? 13:12}}
										           label="RFC" autoComplete={'off'}
										           value={this.state.rfc}
										           required={this.state.receptor_factura}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PinDropOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="calle"
										           onChange={this.handleChange}
										           label="Calle" autoComplete={'off'}
										           value={this.state.calle}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_exterior"
										           onChange={this.handleChange}
										           label="Numero Exterior" autoComplete={'off'}
										           value={this.state.numero_exterior}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_interior"
										           onChange={this.handleChange}
										           label="Numero Interior" autoComplete={'off'}
										           value={this.state.numero_interior}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="codigo_postal"
										           onChange={this.handleChange}
										           required={this.state.receptor_factura}
										           label="Código Postal" autoComplete={'off'}
										           inputProps={{maxLength: 5}}
										           onKeyPress={EnteroSolo}
										           value={this.state.codigo_postal}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="colonia"
										           onChange={this.handleChange}
										           label="Colonia" autoComplete={'off'}
										           value={this.state.colonia}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Estado</InputLabel>
											<Select
												native
												value={this.state.id_cat_estado}
												onChange={(e) => {
													this.handleChange(e);
													this.ListMunicipio(e);
												}}
												name="id_cat_estado"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_estado.map(option => (
													<option key={option.id_cat_estado}
													        value={option.id_cat_estado}>
														{option.estado}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrongOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										
										<FormControl fullWidth>
											<InputLabel>Municipio</InputLabel>
											<Select
												native
												value={this.state.id_cat_municipio}
												onChange={this.handleChange}
												name="id_cat_municipio"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_municipio.map(option => (
													<option key={option.id_cat_municipio}
													        value={option.id_cat_municipio}>
														{option.municipio}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="referencia"
										           onChange={this.handleChange}
										           label="Referencia" autoComplete={'off'}
										           value={this.state.referencia}
										           multiline
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Description className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="regimen_fiscal"
										           onChange={this.handleChange}
										           label="Régimen Fiscal" autoComplete={'off'}
										           value={this.state.regimen_fiscal}
										           multiline
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>*/}
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="apoderado_legal"
										           onChange={this.handleChange}
										           label="Apoderado Legal" autoComplete={'off'}
										           value={this.state.apoderado_legal}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='is_socio' checked={this.state.is_socio}
											          onChange={this.handleChange} value="is_socio" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Es Socio'}
									/>
								</FormGroup>
							
							</Grid>
							
							{this.state.receptor_factura ? (
								<Fragment>
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth required>
													<InputLabel>País</InputLabel>
													<Select
														native
														value={this.state.id_cat_pais}
														onChange={this.handleChange}
														name="id_cat_pais"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_pais.map(option => (
															<option key={option.id_cat_pais}
															        value={option.id_cat_pais}>
																{option.pais}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Description className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'}
												           fullWidth
												           name="registro_identidad_fiscal"
												           onChange={this.handleChange}
												           label="No. Registro Ident. Fiscal" autoComplete={'off'}
												           value={this.state.registro_identidad_fiscal}
												           multiline
												           disabled={this.props.tipo === 'view' || Number(this.state.id_cat_pais) === 401}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={9} lg={9} xl={9}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth required>
													<InputLabel>Régimen Fiscal</InputLabel>
													<Select
														native
														value={this.state.id_cat_regimen_fiscal}
														onChange={this.handleChange}
														name="id_cat_regimen_fiscal"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_regimen_fiscal.map(option => (
															<option key={option.id_cat_regimen_fiscal}
															        value={option.id_cat_regimen_fiscal}>
																{option.regimen_fiscal} - {option.descripcion}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>
							) : null}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox type="checkbox" name='activo'
													          checked={this.state.activo}
													          onChange={this.handleChange} value="activo"
													          color="primary"
													          disabled={this.props.tipo === 'view'}/>
												}
												label={'Activo'}
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<label>
									<TituloHeaderModal
										tipo={this.state.tipo}
										titulo={'Contacto'}
									/>
								</label>
							</Grid>
							
							{!this.is_view() ? (
								<Fragment>
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_nombre"
												           onChange={this.handleChange}
												           label="Nombre" autoComplete={'off'}
												           value={this.state.contacto_nombre}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_apellido_paterno"
												           onChange={this.handleChange}
												           required
												           label="Apellido Paterno" autoComplete={'off'}
												           value={this.state.contacto_apellido_paterno}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_apellido_materno"
												           onChange={this.handleChange}
												           label="Apellido Materno" autoComplete={'off'}
												           value={this.state.contacto_apellido_materno}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AlternateEmailOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_correo_electronico"
												           onChange={this.handleChange}
												           required
												           label="Correo electrónico" autoComplete={'off'}
												           value={this.state.contacto_correo_electronico}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<PhoneIphoneOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_celular"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 10}}
												           onKeyPress={EnteroSolo}
												           required
												           label="Celular" autoComplete={'off'}
												           value={this.state.contacto_celular}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={9} sm={9} md={9} lg={9} xl={9} align={"right"}>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title="Cancelar edición"
											children={(
												<span>
											<Fab size="small" color="secondary"
											     aria-label="Cancelar edición"
											     onClick={() => this.edit_cancel()}
											     style={{marginLeft: "15px"}}
											     disabled={!this.is_edited_product()}>
												<CancelOutlined/>
											</Fab>
										</span>
											)}
										/>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title={this.is_edited_product() ? "Actualizar" : "Agregar"}
											children={(
												<Fab size="small" color="secondary"
												     aria-label={this.is_edited_product() ? "Actualizar" : "Agregar"}
												     onClick={() => this.add_contacto()}
												     style={{marginLeft: "15px"}}>
													{this.is_edited_product() ? (
														<SaveOutlined/>
													) : (
														<AddOutlined/>
													)}
												</Fab>
											)}
										/>
									</Grid>
								
								</Fragment>
							): ''}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th>Nombre</th>
										<th>Apellido paterno</th>
										<th>Apellido materno</th>
										<th>Correo electrónico</th>
										<th>Celular</th>
										{!this.is_view() ? (<th className={'text-center'}>Acciones</th>) : null}
									</tr>
									</thead>
									<tbody>
									{this.state.lista_contactos.map((value, index) => (
											<tr key={index}>
												<td>{value.nombre}</td>
												<td>{value.apellido_paterno}</td>
												<td>{value.apellido_materno}</td>
												<td>{value.correo_electronico}</td>
												<td>{value.celular}</td>
												
												{!this.is_view() ? (
													<td align={'right'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Eliminar"
															children={(
																<Fab
																	size="small"
																	aria-label="Eliminar"
																	onClick={() => this.delete(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<DeleteOutlined/>
																	)}
																/>
															)}
														/>
														{this.state.key === index ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Producto en edición"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_contacto(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "#f50057",
																			color: "white",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														) : (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Editr producto"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_contacto(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#808080",
																			boxShadow: "none",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														)}
													</td>
												) : null}
											</tr>
										)
									)}
									{!(this.state.lista_contactos.length > 0) ? (
										<tr>
											<td colSpan={!this.is_view() ? (is_root ? 13 : 6) : (is_root ? 12 : 5)}
											    align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ningún contacto agregado.'}
													image={carrito_de_compra_3}
													padding={'10px'}
													paddingText={'10px'}
													height={'60px'}
													width={'60px'}
												/>
											</td>
										</tr>
									) : null}
									</tbody>
								</table>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_acreditado}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCliente.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCliente;
