import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ClienteService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				cliente: filtro.cliente || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let params = {
			id_acreditado: null,
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1 : 2,
			razon_social: form.razon_social,
			nombre_comercial: form.nombre_comercial,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			celular: form.celular,
			rfc: form.rfc,
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal,
			id_cat_pais: form.id_cat_pais,
			curp: form.curp,
			receptor_factura: form.receptor_factura,
			activo: form.activo ? 1 : 0,
			is_socio: form.is_socio ? 1 : 0,
			
			id_acreditado_direccion: null,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			regimen_fiscal: form.regimen_fiscal,
			id_cat_municipio: form.id_cat_municipio,
			id_cat_estado: form.id_cat_estado,
			lista_contactos: form.lista_contactos,
			apoderado_legal: form.apoderado_legal,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_acreditado: form.id_acreditado,
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1 : 2,
			id_cat_giro_cliente: form.id_cat_giro_cliente,
			razon_social: form.razon_social,
			nombre_comercial: form.nombre_comercial,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			celular: form.celular,
			rfc: form.rfc,
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal,
			id_cat_pais: form.id_cat_pais,
			curp: form.curp,
			receptor_factura: form.receptor_factura,
			activo: form.activo ? 1 : 0,
			is_socio: form.is_socio ? 1 : 0,
			
			id_acreditado_direccion: form.id_acreditado_direccion,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			regimen_fiscal: form.regimen_fiscal,
			id_cat_municipio: form.id_cat_municipio,
			id_cat_estado: form.id_cat_estado,
			apoderado_legal: form.apoderado_legal,
			
			lista_contactos: form.lista_contactos,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_acreditado) => {
		let params = {
			id_acreditado: id_acreditado
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ChatDatos: (form, modulo) => {
		let params = {
			filtro: {
				id_acreditado: form.id_acreditado || null,
				id_ficha_tecnica: form.id_ficha_tecnica || null,
				modulo: modulo,
				activo: 1,
			},
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Chat_Datos', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ChatGuardar: (form) => {
		let params = {
			id_acreditado: form.id_acreditado,
			id_ficha_tecnica: form.id_ficha_tecnica,
			sms: form.sms,
			modulo: form.modulo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Chat_Guardar', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
