import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {Adjust, Person, SearchOutlined, ListAlt} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";


class FiltrosRepositorioBitacora extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			fecha: null,
			id_repositorio_juridico: '',
			usuario: '',
			actividad: '',
			activo: 'all',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.fecha = this.state.fecha || undefined;
		filtro.id_repositorio_juridico = this.state.id_repositorio_juridico || undefined;
		filtro.usuario = this.state.usuario || undefined;
		filtro.actividad = this.state.actividad || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<KeyboardDatePicker
							format={'dd/MM/yyyy'}
							fullWidth
							clearable
							inputProps={{readOnly: true}}
							KeyboardButtonProps={{
								'aria-label': 'Fecha',
							}}
							label="Fecha"
							value={this.state.fecha}
							onChange={(date) => {
								this.handleChange(null, null, null, date, 'fecha');
							}}
							// minDate={moment(new Date())}
							clearLabel={'Limpiar'}
							okLabel={'Aceptar'}
							cancelLabel={'Cancelar'}
							onError={console.log}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<Adjust className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									margin="none"
									name="id_repositorio_juridico"
									label="Id"
									type="text"
									fullWidth
									value={this.state.id_repositorio_juridico}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="usuario"
											label="Usuario"
											value={this.state.usuario}
										/>
									</Grid>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="actividad"
											label="Actividad"
											value={this.state.actividad}
										/>
									</Grid>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'center'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosRepositorioBitacora.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosRepositorioBitacora;
