import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import ListaTabla from './Includes/ListaTabla';
import ModalUsuarios from './Includes/ModalUsuarios';
import {UsuariosService} from '../../../services/_Sis/UsuariosService/UsuariosService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {BotonActualizarLista, BotonExportarListaExcel, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import DialogTitle from "@material-ui/core/DialogTitle";

class Usuarios extends Component {
	
	Usr = {};
	
	state = {
		listar_usuarios: []
	};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.Listar = this.Listar.bind(this);
		this.$BroadcastModalUsuarios = this.$BroadcastModalUsuarios.bind(this);
		
		this.Listar();
		
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	$BroadcastModalUsuarios = (data) => {
		console.warn('$BroadcastModalUsuarios::', data);
		switch (data.accion) {
			case 'delete':
				if (data.status === true) {
					this.Eliminar(data.item);
				}
				break;
			case 'list':
				if (data.status === true) {
					this.Listar();
				}
				break;
			default:
				if (data.status === true) {
					this.Listar();
				}
		}
	};
	
	Listar = () => {
		UsuariosService.Listar().then((response) => {
			
			this.setState({listar_usuarios: response.data});
			
		}).catch((error) => {
			
			this.setState({listar_usuarios: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el usuario ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				UsuariosService.Eliminar(item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {};

		HttpRequest.export('usuarios', ['usuarios'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};

	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<DialogTitle style={{color: 'gray'}}>
					<CabeceraTituloPdfExcelLista
						titulo={'Usuarios'}
						botonEXCEL={
							<Fragment>
								<div className={'v-center'}>
									<Fragment>
										<BotonExportarListaExcel onClick={() => this.exportar({}, 'EXCEL', true)}/>
									</Fragment>
								</div>
							</Fragment>
						}
						botonLISTA={
							<Fragment>
								<div className={'v-center'}>
									<Fragment>
										<BotonActualizarLista onClick={this.Listar}/>
									</Fragment>
								</div>
							</Fragment>
						}
					/>
				</DialogTitle>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_usuarios.length > 0 ? (
						<ListaTabla lista={this.state.listar_usuarios}
						            $BroadcastModalUsuarios={this.$BroadcastModalUsuarios}/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
					<ModalUsuarios id_usuario={null} tipo={'add'}
					               $BroadcastModalUsuarios={this.$BroadcastModalUsuarios}/>
				) : ''}
			
			</div>
		);
	}
}

Usuarios.propTypes = {
};

export default IntegrationNotistack(Usuarios);
